<script>
    import Loader from './Loader.svelte';
    import Popup from './Popup.svelte';
    import Heading from '../components/Heading.svelte';
    import User from '../services/User.js';
    import PopupHelper from '../helpers/Popup';

    let isPopupOpen = false;
    let isLoading = false;

    const tamanhoSenha = 16;
    let senhaGerada = "";

    let emailInput = "";

    function handleInputText(e){
        emailInput = e.target.value.toLowerCase();
    }

    function gerarSenha(tamanho) {
        const caracteres = 'ABCDEFGHIJKLMNOPQRSTUVXWYZabcdefghijklmnopqrstuvxwyz!@#123456789';
        let senha = '';

        for(let i=0; i<tamanho; i++) {
            const indiceAleatorio = Math.floor(Math.random() * caracteres.length);
            senha += caracteres.charAt(indiceAleatorio);
        }

        return senha;
    }

    async function handleSubmit(e) {
        e.preventDefault();

        senhaGerada = gerarSenha(tamanhoSenha)

        const dominioLoovi = "@loovi.com.br";

        isLoading = true;
        let emailLimpo = emailInput.split('@')[0];
        let emailCompleto = emailLimpo + dominioLoovi;
        emailCompleto = removerEspacos(emailCompleto).toLowerCase();

        const result = await User.createFuncionario(emailCompleto, senhaGerada);

        if(result){
            limpaInputs();
            isLoading = false;
            isPopupOpen = false;
            isPopupOpen = true;
        }else{
            PopupHelper.alert('Erro de Servidor', result.error);
            isLoading = false;
        }
    }

    function removerEspacos(entrada){
        return entrada.replace(/\s+/g, '');
    }

    function limpaInputs(){
        emailInput = "";
    }
</script>

<Loader visible={isLoading} />

<main>
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <div class="pai">
            <Heading main="Criar usuário do Mercúrio" />

            <div class="painel">
                <form on:submit={handleSubmit}>
                    <div>
                        <h1>Insira o email:</h1>
                        <input type="text" class="inputEmail" on:input={handleInputText} value={emailInput} spellcheck="false">
                        <label class="spanGmail">@loovi.com.br</label>
                    </div>

                    {#if emailInput.length > 3}
                        <div class="divSubmit">
                            <input class="btn altura" type="submit" value="Criar">
                        </div>
                    {/if}
                </form>
            </div>
        </div>
</main>

<Popup
    visible={isPopupOpen}
    title="Usuário criado com sucesso!"
    message={`Senha: ${senhaGerada}`} />

<style>
    .divSubmit{
        text-align: center;
        margin-top: 25px;
    }

    .altura{
        height: 35px;
        cursor: pointer;
    }

    .inputEmail{
        height: 30px;
        border-radius: 5px 0px 0px 5px;
        border: 1px solid #333;
        font-size: 15px;
        width: 140px;
        padding: 10px;
    }

    .spanGmail{
        border-radius: 0px 5px 5px 0px;
        display: inline-block;
        height: 30px;
        margin-left: -4px;
        font-size: 14px;
        transition: .5s;
        border: 1px solid #333;
        text-align: center;
        align-items: center;
        justify-content: center;
        padding: 7.5px;
        background-color: #D0D0D0;
        font-weight: bold;
    }

    form h1{
        margin-bottom: 5px;
        font-weight: bold;
        font-size: 15px;
    }

    .pai{
        max-width: 1004px;
        margin: 0 auto;
    }

    .painel{
        background: #fff;
        border-radius: 5px;
        border: 1px solid var(--color-black-20);
        display: flex;
    }

    form{
        width: 100%;
        padding: 11px 13px;
    }
</style>