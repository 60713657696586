import { URLS, api_keyed } from '../helpers/Request';

export default {
	async postLoteEnvia(body){
		try {
			const data = await api_keyed.post(`${URLS.SMS}/lote/envia`, {
                json: body
            });

			if (data === false) {
				return data;
			}

			if (data.TipoErro) return false;
			return data;
		} catch (e) {
			console.error(e);
			return false;
		}
	}
};
