import { api_auth, api, URLS } from "../helpers/Request";

export default {
  async getPictures(contract) {
    try {
      const data = await api_auth.get(`${URLS.FOTOS}/${contract}`).json();

      if (data === false) {
        return data;
      }

      if (data.TipoErro) return false;
      return data;
    } catch (e) {
      console.error(e);
      return false;
    }
  },
  async getPictureUrl(pictureTypeId, contract) {
    try {
      const data = await api_auth.get(`${URLS.FOTOS}/url-download/${contract}/${pictureTypeId}`).json();

      if (data === false) {
        return data;
      }
      
      if (data.TipoErro) return false;
      return data;
    } catch (e) {
      console.error(e);
      return false;
    }
  },
  async approvePicture(pictureTypeId, contract) {
    try {
      await api_auth.put(`${URLS.FOTOS}/aprova/${contract}/${pictureTypeId}`);
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  },
  async reprovePicture(pictureTypeId, contract, motivoReprovacao) {
    try {
      await api_auth.put(`${URLS.FOTOS}/reprova/${contract}/${pictureTypeId}?motivo-reprovacao=${motivoReprovacao}`);
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  },
  async resetPictures(contract) {
    try {
      await api_auth.post(`${URLS.FOTOS}/status/reset/${contract}`);
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  },
  async resetPicture(pictureTypeId, contract) {
    try {
      await api_auth.post(`${URLS.FOTOS}/status/reset/foto/${contract}/${pictureTypeId}`);
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  },
  async getContractsWithPendingPictures(amount, page) {
    try {
      if (page) {
        return await api_auth.get(`${URLS.FOTOS}/status/pendentes/${amount}/${page}`).json();
      } else {
        return await api_auth.get(`${URLS.FOTOS}/status/pendentes/${amount}`).json();
      }
    } catch (e) {
      console.error(e);
      return false;
    }
  },
  async uploadPicture(body, contract, pictureTypeId, formato){
		try {
      const data = await api_auth.put(`${URLS.FOTOS}/upload/${contract}/${pictureTypeId}/${formato}`, {
        headers: {
          'Content-Type': 'text/plain'
        },
        body: body
      });

      if (data === false) {
        return data;
      }

      if (data.TipoErro) return false;
      return data;
		} catch (e) {
			console.error(e);
			return false;
		}
	},
}
