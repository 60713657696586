import ky from 'ky';
import { auth, hasTemporaryIdToken, isLogged, updateTokens, updateTokensAdministrador, retornaGrupoUsuario } from '../stores/Auth';
import { contract } from '../stores/Contract';
import Env from './Env';
import PopupHelper from './Popup';

const ENVIRONMENT = process.env.ENVIRONMENT;

export const URLS = Env.API;

const { pathname } = location;

function getTipoCasoDaURL(url) {
  const parametrosURL = url.split('/');
  return parametrosURL[parametrosURL.length - 2];
}
let tipoCaso = getTipoCasoDaURL(pathname);

// Atualiza o tipoCaso, sempre que a URL for alterada
function atualizarTipoCaso() {
  const { pathname } = location;
  tipoCaso = getTipoCasoDaURL(pathname);
}
window.addEventListener('popstate', atualizarTipoCaso);

const API_KEY_LIST = {
  staging: '6aY0lmR6Xn0WpCsEQN7G5cVB6zCvE6s5MGRYgGEe',
  development: 'VmVnbVhX3J57odqT25wws3tMcxWIMMXZ6yIj5AyS',
  production: 'oy23lJhZRG5s6LLrNobHJ6ECcyCHG3mI6OXV3BuF',
}

export const API_KEY = API_KEY_LIST[ENVIRONMENT];

export const MAX_REQUEST_TIME = 90000;

// Retorna x-api-key para api-documentos-auxiliares
export const api_auth = ky.create({
  timeout: MAX_REQUEST_TIME,
  hooks: {
    beforeRequest: [
      async (request) => {
        if(tipoCaso == 'terceiros' || tipoCaso == 'indenizacao'){
          return request.headers.set('x-api-key', API_KEY_LIST[ENVIRONMENT]);
        }
        if (isLogged() || hasTemporaryIdToken()) {
          request.headers.set('id-token', auth.get().idToken || contract.get().idToken);
          return request.headers.set('x-api-key', API_KEY_LIST[ENVIRONMENT]);
        } else {
          if(retornaGrupoUsuario() == 'administradores'){
            await updateTokensAdministrador();
          }else{
            await updateTokens();
          }
          request.headers.set('id-token', auth.get().idToken);
          return request.headers.set('x-api-key', API_KEY_LIST[ENVIRONMENT]);
        }
      }
    ],
    afterResponse: [
      async (request, options, response) => {
        if (!response.ok) {
          try {
            const payload = await response.json();

            if (payload.Erro === 'furto-roubo-nao-aberto') {
              return false;
            }

            PopupHelper.alert('Ops!', payload.DescricaoErroCliente || 'Não foi possível completar essa requisição. Tente novamente. (Erro de Servidor)');
            return false;
          } catch(e) {
            console.error(e);
            return false;
          }
        }
      }
    ],
  }
});

// Retorna id-token
export const api_auth_status = ky.create({
  timeout: MAX_REQUEST_TIME,
  hooks: {
    beforeRequest: [
      async (request) => {
        if (isLogged() || hasTemporaryIdToken()) {
          return request.headers.set('id-token', auth.get().idToken || contract.get().idToken);
        } else {
          if(retornaGrupoUsuario() == 'administradores'){
            await updateTokensAdministrador();
          }else{
            await updateTokens();
          }
          return request.headers.set('id-token', auth.get().idToken);
        }
      }
    ],
  }
});

export const api_keyed = ky.create({
  timeout: MAX_REQUEST_TIME,
  hooks: {
    beforeRequest: [
      request => request.headers.set('x-api-key', API_KEY_LIST[ENVIRONMENT])
    ],
    afterResponse: [
      async (request, options, response) => {
        if (!response.ok) {
          try {
            const payload = await response.json();
            PopupHelper.alert('Ops!', payload.DescricaoErroCliente || 'Erro de Servidor.');
            return false;
          } catch(e) {
            console.error(e);
            return false;
          }
        }
      }
    ],
  },
});

export const api = ky.create({
  timeout: MAX_REQUEST_TIME
});

export const api_keyed_login = ky.create({
  timeout: MAX_REQUEST_TIME,
  hooks: {
    beforeRequest: [
      request => request.headers.set('x-api-key', API_KEY_LIST[ENVIRONMENT])
    ]
  }
});