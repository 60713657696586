import { writable, get } from 'svelte/store';

function $vehicle() {
	const { subscribe, set } = writable({ routes: [] });

	return {
    subscribe,
    set(data) {
      set(data);
    },
    get() {
      return get(this);
    }
	};
}

export const vehicle = $vehicle();