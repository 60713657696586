<script>
	import { onMount } from 'svelte';
	import { contract } from '../stores/Contract';
	import EventsService from '../services/Events';
	import Loader from './Loader.svelte';
	import Popup from './Popup.svelte';
import PopupHelper from '../helpers/Popup';

  export let imei = false;
  export let useImei = false;
  let currentIndex = false;
	let currentValue = '';
	let commands = [];
	let isLoading = false;
  let isPopupOpen = false;
  let fieldHeight = 15;
  
  $: dynamicHeight = (() => {
    return 34 + fieldHeight * (currentValue.split('\n').length - 1);
  })()

	function updateValue(e) {
    currentIndex = +e.target.value;
    const commandList = commands[currentIndex].Comandos.join('\n');

    if (currentValue === '') {
      currentValue = commandList || '';
    } else {
      currentValue = `${currentValue}\n${commandList}`;
    }
  }
  
  function getCommandsFromInput() {
    const cmds = currentValue.split('\n').map(cmd => cmd.trim()).filter(cmd => cmd !== '');
    return cmds;
  }

  function canSendByImei() {
    if (!useImei) {
      return true;
    }

    return useImei && imei;
  }

  function imeiError() {
    PopupHelper.alert('Comando sem IMEI', 'Não foi possível enviar comando, preencha o campo IMEI primeiro.');
  }

	async function sendSms() {
    if (!canSendByImei()) {
      imeiError();
      return;
    }

    isLoading = true;
    let result;
    const cmds = getCommandsFromInput();

    if (cmds.length === 1) {
      result = await EventsService.sendCommandSms(
        imei || $contract.contractNumber,
        cmds[0]
      );
      isLoading = false;
    } else {
      result = await EventsService.sendCommandSmsLote(
        imei || $contract.contractNumber,
        cmds
      );
      isLoading = false;
    }

		if (result) {
			isPopupOpen = false;
			isPopupOpen = true;
		}
	}

	async function sendSocket() {
    if (!canSendByImei()) {
      imeiError();
      return;
    }

    isLoading = true;
    
    let result;
    const cmds = getCommandsFromInput();

    if (cmds.length === 1) {
      result = await EventsService.sendCommandSocket(
        imei || $contract.contractNumber,
        cmds[0]
      );
      isLoading = false;
    } else {
        result = await EventsService.sendCommandSocketLote(
        imei || $contract.contractNumber,
        cmds
      );
      isLoading = false;
    }

		isLoading = false;

		if (result) {
			isPopupOpen = false;
			isPopupOpen = true;
		}
	}

	onMount(async () => {
		const commandsData = await EventsService.getCommands();

		if (commandsData) {
			commands = commandsData;
		}
	});
</script>

<style>
	.panel__row {
		padding: 11px 13px;
	}

	.panel__forms__value {
		position: relative;
	}

	.panel__forms__value .field {
		width: 100%;
	}

	.actions {
		position: absolute;
		right: 0;
		height: 34px;
		display: flex;
		align-items: flex-end;
		border-left: 1px solid var(--color-black-30);
		padding: 5px;
	}

	.actions__separator {
		width: 1px;
		height: 20px;
		margin: 0 5px;
		background: var(--color-black-15);
	}

	.actions__btn__sms {
		font-size: 12px;
		color: #fff;
		text-align: center;
		padding: 4px 10px;
		background: var(--color-status-success);
		border: none;
		border-radius: 2px;
	}

	.actions__btn__socket {
		font-size: 12px;
		color: #fff;
		text-align: center;
		padding: 4px 10px;
		background: var(--color-blue);
		border: none;
		border-radius: 2px;
	}

  .actions__btn__sms:disabled,
  .actions__btn__socket:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

	.field--command {
    padding-right: 130px;
    min-height: 34px;
    padding-top: 8px;
    resize: none;
    transition: none;
	}

	.action_details {
		flex: 1;
		padding: 20px 13px;
		margin: -13px;
		border-top: 1px solid var(--color-black-30);
	}

	.action_details__box {
		background: var(--color-black-10);
		border-radius: 3px;
		padding: 15px;
		opacity: 0.9;
	}

	.action_details h3 {
		font-weight: bold;
		margin-bottom: 5px;
  }
  
  .panel__row--vertical .panel__forms__col {
    margin-bottom: 20px;
    margin-right: 0;
  }
</style>

<div class="panel">
	<div class="panel__head">
		<h2 class="panel__head__title">
      Envio de Comandos

      {#if imei}
       para IMEI: {imei}
      {/if}
    </h2>
	</div>
	<div class="panel__row">
		<div class="info info--error">
			<b>Atenção:</b>
			Os comandos abaixo serão executados imediatamente após clicar no botão
			(SMS ou Socket), use-os com moderação.
		</div>
	</div>
	<div class="panel__row panel__row--vertical">
		<div class="panel__forms__col">
			<label class="panel__forms__label">Comandos Sugeridos:</label>
			<div class="panel__forms__value">
				<select class="field field--grey" on:change={updateValue}>
					<option value="">Selecionar comando...</option>
					{#each commands as command, index}
						<option value={index}>{command.Titulo}</option>
					{/each}
				</select>
			</div>
		</div>
		<div class="panel__forms__col panel__forms__col--1">
			<label class="panel__forms__label">Enviar Comandos:</label>
			<div class="panel__forms__value">
				<textarea
					type="text"
          bind:value={currentValue}
          style={`height: ${dynamicHeight}px`}
					class="field field--grey field--command"
					placeholder="Digite ou selecione um comando ao lado, aperte ENTER para adicionar mais de um comando."></textarea>
				<div class="actions" style={`height: ${dynamicHeight}px`}>
					<button class="actions__btn__sms" on:click={sendSms} disabled={currentValue.trim().length === 0}>SMS</button>
					<div class="actions__separator" />
					<button class="actions__btn__socket" on:click={sendSocket} disabled={currentValue.trim().length === 0}>Socket</button>
				</div>
			</div>
		</div>
	</div>
	{#if currentIndex && commands[currentIndex].Descricao}
		<div class="panel__row">
			<div class="action_details">
				<div class="action_details__box">
					<h3>Descrição do Comando:</h3>
					<p>{commands[currentIndex].Descricao}</p>
				</div>
			</div>
		</div>
	{/if}
</div>

<Loader visible={isLoading} />
<Popup
	visible={isPopupOpen}
	title="Executado com Sucesso!"
	message="Comando executado com sucesso." />
