<script>
	import { Link, navigateTo } from 'svero';
	import Loader from '../components/Loader.svelte';
	import UserService from '../services/User';
	import { auth } from '../stores/Auth';
	import Popup from '../components/Popup.svelte';

	let isLoading = false;
	let formEmail = '';
	let formPassword = '';
  	let blurInput;
	let pool = null;
	let isPopupOpen = false;

	async function handleLogin(){
		blurInput.focus();
		isLoading = true;
		let result = null;

		try{
			result = await UserService.loginAdministrador(formEmail, formPassword);
			pool = 'adm'
    	}catch(error){
			try{
				result = await UserService.login(formEmail, formPassword);
				pool = 'func'
			}catch(adminError){
				isLoading = false;
				isPopupOpen = false;
				isPopupOpen = true;
				return;
			}
    	}

		isLoading = false;

		if(result){
			auth.login(formEmail, result.IdToken, result.RefreshToken, result.AccessToken, pool);
			navigateTo('/busca-contrato');
		}
	}

</script>

<Loader visible={isLoading} />

<Popup
    visible={isPopupOpen}
    title="Usuário não encontrado"
    message="Tente novamente."
/>

<main class="login-page"> 
	<div class="body">
		<img src="/img/logo-white.png" alt="Mercurio" class="logo" />

		<h2>Autenticação de Usuário ({process.env.ENVIRONMENT})</h2>

		<form class="form" on:submit|preventDefault={handleLogin}>
			<img src="/img/icon-login-locker.svg" alt="" />
			<input type="text" class="field field--login" bind:value={formEmail} />
			<input type="password" class="field field--login" bind:value={formPassword} />
			<div class="action">
				<Link href="/esqueci-senha">Esqueceu sua senha?</Link>
				<button class="btn">Entrar</button>
			</div>
		</form>

		<input type="text" class="hidden-blur-field" bind:this={blurInput}>

		<div class="footer">
			<p>Produto de propriedade Loovi. Todos os direitos reservados. <br> v{window['APP_VERSION']}</p>
			<p>
				by
				<img src="/img/logo-loovi.svg" alt="Loovi" />
			</p>
		</div>
	</div>
</main>

<style>
	.action button{
		cursor: pointer;
	}

	.login-page {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.login-page:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 50%;
		background: linear-gradient(90deg, var(--color-blue), var(--color-green));
		z-index: var(--z-1);
	}

	.body {
		position: relative;
		z-index: var(--z-2);
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.body h2 {
		font-size: 2rem;
		font-weight: bold;
		color: #fff;
		margin: 25px 0;
	}

	.logo {
		width: 132px;
	}

	.form {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 340px;
		padding: 15px 18px;
		background: #fff;
		border-radius: 5px;
		box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
	}

	.form img {
		margin-bottom: 10px;
	}

	.form .field {
		margin-bottom: 10px;
	}

	.form .action {
		min-width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.footer {
		display: flex;
		flex-direction: column;
		align-items: center;
		max-width: 400px;
		margin-top: 10px;
		color: var(--color-blue-dark);
	}

	.footer p {
		margin: 5px 0;
		text-align: center;
		line-height: 20px;
	}

	.hidden-blur-field {
		position: fixed;
		top: 10px;
		right: 10px;
		z-index: 10000;
		pointer-events: none;
		width: 10px;
		opacity: 0;
	}
</style>