import { expoOut } from 'eases-jsnext';

export function scale(node, { delay = 0, duration = 500, easing = expoOut }) {
  const o = +getComputedStyle(node).opacity;

  return {
    delay,
    duration,
    easing,
    css(t) {
      return `opacity: ${t * o}; transform: scale(${t})`;
    }
  };
}

export function moveRight(node, { delay = 0, duration = 500, easing = expoOut }) {
  const o = +getComputedStyle(node).opacity;

  return {
    delay,
    duration,
    easing,
    css(t) {
      return `transform: translate3d(${100 + (t * 100 * -1)}%, 0, 0)`;
    }
  };
}