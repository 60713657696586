<script>
  import { onMount } from 'svelte';
	import { Router, Route } from 'svero';
	import { canRelogin, isLogged, logout, updateTokens, updateTokensAdministrador, isLoggedAdmin, retornaGrupoUsuario } from './stores/Auth';
	import NotFound from './pages/NotFound.svelte';
	import Login from './pages/Login.svelte';
	import ForgotPassword from './pages/ForgotPassword.svelte';
	import Auth from './pages/Auth.svelte';
	import SearchContract from './pages/SearchContract.svelte';
	import ValidatePhotosDetail from './pages/ValidatePhotosDetail.svelte';
	import Account from './pages/Account.svelte';
	import VehicleMap from './pages/VehicleMap.svelte';
	import VehicleMapTemporary from './pages/VehicleMapTemporary.svelte';
	import VehiclesWithError from './pages/VehiclesWithError.svelte';
	import VehiclesInTheft from './pages/VehiclesInTheft.svelte';
	import TheftRobberyDetail from './pages/TheftRobberyDetail.svelte';
	import Events from './pages/Events.svelte';
	import EventsWithoutAssociation from './pages/EventsWithoutAssociation.svelte';
	import ValidatePhotosSinister from './pages/ValidatePhotosSinister.svelte';
	import SendSms from './pages/SendSms.svelte';
	import UploadFotos from './pages/UploadFotos.svelte';
	import CreateUser from './pages/CreateUser.svelte';
	import CreateGmail from './pages/CreateGmail.svelte';

  function isLoggedAndSaveURL() {
    if (!window.location.pathname.includes('/busca-contrato')) {
      localStorage.setItem('mercurio-admin-url', window.location.pathname);
    }

    // Caso token esteja expirado, mas possua o refreshToken
    // dispara a atualização dos tokens e continua normalmente.
    if (!isLogged() && canRelogin()) {

		if(retornaGrupoUsuario() == 'administradores'){
			updateTokensAdministrador();
		}else{
			updateTokens();
		}
      return true;
    }
    
    return isLogged();
  }

  onMount(() => {
    if (!canRelogin() && !window.location.pathname.includes('/login') && !window.location.pathname.includes('/mapa-temporario')) {
      logout();
    }
  });
</script>

<Router>
	<Route path="/" redirect="/busca-contrato" />
	<Route path="/login" component={Login} />
	<Route path="/esqueci-senha" component={ForgotPassword} />
	<Route path="/auth/:token" component={Auth} />
	<Route path="/busca-contrato" condition={isLoggedAndSaveURL} redirect="/login" component={SearchContract} />
	<Route path="/buscar-erros" condition={isLoggedAndSaveURL} redirect="/busca-contrato" component={VehiclesWithError} />
	<Route path="/buscar-furto-roubo" condition={isLoggedAndSaveURL} redirect="/busca-contrato" component={VehiclesInTheft} />
	<Route path="/:contract/furto-roubo" condition={isLoggedAndSaveURL} redirect="/busca-contrato" component={TheftRobberyDetail} />
	<Route path="/:contract/validar-fotos" condition={isLoggedAndSaveURL} redirect="/busca-contrato" component={ValidatePhotosDetail} />
	<Route path="/:contract/validar-fotos/:tipoCaso/:sinisterCode" condition={isLoggedAndSaveURL} redirect="/busca-contrato" component={ValidatePhotosSinister} />
  	<Route path="/:contract/eventos" condition={isLoggedAndSaveURL} redirect="/busca-contrato" component={Events} />
  	<Route path="/eventos/nao-associados" condition={isLoggedAndSaveURL} redirect="/busca-contrato" component={EventsWithoutAssociation} />
  	<Route path="/:contract/mapa-veiculo" condition={isLoggedAndSaveURL} redirect="/busca-contrato" component={VehicleMap} />
  	<Route path="/:contract/mapa-veiculo/:travel" condition={isLoggedAndSaveURL} redirect="/busca-contrato" component={VehicleMap} />
	<Route path="/mapa-temporario/:contract/:token" component={VehicleMapTemporary} />
	<Route path="/minha-conta" condition={isLoggedAndSaveURL} redirect="/login" component={Account} />
	<Route path="/sms" condition={isLoggedAdmin} redirect="/busca-contrato" component={SendSms} />
	<!-- <Route path="/upload-fotos" condition={isLoggedAdmin} redirect="/busca-contrato" component={UploadFotos} /> -->
	<Route path="/criar-usuario" condition={isLoggedAdmin} redirect="/busca-contrato" component={CreateUser} />
	<Route path="/criar-email" condition={isLoggedAdmin} redirect="/busca-contrato" component={CreateGmail} />
	<Route path="*" component={NotFound} condition={isLoggedAndSaveURL} redirect="/login" />
</Router>