<script>
	import { onDestroy, onMount, tick } from 'svelte';
	import { fade } from 'svelte/transition';
	import { navigateTo } from 'svero';
	import Menu from '../components/Menu.svelte';
	import Footer from '../components/Footer.svelte';
	import Popup from '../components/Popup.svelte';
	import Loader from '../components/Loader.svelte';
	import PicturesSinisterService from '../services/PicturesSinister';
  	import { contract } from '../stores/Contract';
  	import { setContract } from '../helpers/SetContract';
	import { wait } from '../helpers/Time';
	import CheckCarouselSinister from '../components/CheckCarouselSinister.svelte';

	export let router = {};
	let pictures = [];
	let isLoading = false;
	let isPopupResetPhotosOpen = false;
	let isPopupResetCurrentOpen = false;
	let isPopupResetPhotosSuccessOpen = false;
	let isPopupResetPhotosErrorOpen = false;
	let currentPhotoTypeToReset = "";

	async function handlePictures(pics) {
		pictures = [];
		await tick();
		
		const promises = pics.map(async pic => {
			pic.type = pic.TipoFoto.Id;

			if (pic.Foto) {
				if(router.params.tipoCaso == 'terceiros' || router.params.tipoCaso == 'indenizacao'){
					pic.id = (pic.Foto || {}).Id || '';
					pic.url = await PicturesSinisterService.getPictureUrlDcoumentosAuxiliares(router.params.sinisterCode, router.params.tipoCaso, pic.type);
					pic.approved = (pic.Foto || {}).Aprovacao;
					pic.hasPic = pic.Foto !== null;	
				}else{
					pic.id = (pic.Foto || {}).Id || '';
					pic.url = await PicturesSinisterService.getPictureUrl(pic.type, router.params.contract || $contract.contractNumber, router.params.sinisterCode);
					pic.approved = (pic.Foto || {}).Aprovacao;
					pic.hasPic = pic.Foto !== null;
				}
			}
		});

		await Promise.all(promises);

		// Sempre espera um pouco (mínimo de 1s) antes de chamar
		// restante do código, para dar tempo do Svelte limpar interface.
		await wait(1000);

		pictures = [...pics];
		isLoading = false;
	}

	async function loadPicturesStatus(contractNumber) {
		isLoading = true;

		if(router.params.tipoCaso == 'terceiros' || router.params.tipoCaso == 'indenizacao'){
			const picturesData = await PicturesSinisterService.getPicturesDocumentosAuxiliares(router.params.tipoCaso, router.params.sinisterCode);

			if (picturesData.error) {
				isLoading = false;
				console.error(picturesData.error);
				return;
			}

			handlePictures(picturesData);
		}else{
			const picturesData = await PicturesSinisterService.getPictures(contractNumber || $contract.contractNumber, router.params.sinisterCode);
	
			if (picturesData.error) {
				isLoading = false;
				console.error(picturesData.error);
				return;
			}
	
			handlePictures(picturesData);
		}

	}

	async function approve(e) {
		const contractNumber = router.params.contract || $contract.contractNumber;
		const pictureTypeId = e.detail.id;
		isLoading = true;

		if(router.params.tipoCaso == 'terceiros' || router.params.tipoCaso == 'indenizacao'){
			const result = await PicturesSinisterService.approvePictureDocumentosAuxiliares(router.params.tipoCaso, router.params.sinisterCode, pictureTypeId);

			if (result.error) {
				isLoading = false;
				PopupHelper.alert('Erro de Servidor', result.message);
				return;
			}

			loadPicturesStatus(router.params.contract);
		}else{
			const result = await PicturesSinisterService.approvePicture(pictureTypeId, contractNumber, router.params.sinisterCode);
	
			if (result.error) {
				isLoading = false;
				PopupHelper.alert('Erro de Servidor', result.message);
				return;
			}
	
			loadPicturesStatus(router.params.contract);
		}
	}

	async function reprove(e) {
		const contractNumber = router.params.contract || $contract.contractNumber;
		const pictureTypeId = e.detail.id;
		const motivoReprovacao = e.detail.selectedReason;
		isLoading = true;

		if(router.params.tipoCaso == 'terceiros' || router.params.tipoCaso == 'indenizacao'){
			const result = await PicturesSinisterService.reprovePictureDocumentosAuxiliares(router.params.tipoCaso, router.params.sinisterCode, pictureTypeId, motivoReprovacao);

			if (result.error) {
				isLoading = false;
				PopupHelper.alert('Erro de Servidor', result.message);
				return;
			}

			loadPicturesStatus(router.params.contract);
		}else{
			const result = await PicturesSinisterService.reprovePicture(contractNumber, pictureTypeId, router.params.sinisterCode, motivoReprovacao);
	
			if (result.error) {
				isLoading = false;
				PopupHelper.alert('Erro de Servidor', result.message);
				return;
			}
	
			loadPicturesStatus(router.params.contract);
		}
	}

	function promptResetPhotos() {
		isPopupResetPhotosOpen = false;
		isPopupResetPhotosOpen = true;
	}

	function promptResetCurrent(e) {
		currentPhotoTypeToReset = e.detail;
		isPopupResetCurrentOpen = false;
		isPopupResetCurrentOpen = true;
	}

	async function resetCurrent(e) {
		if (e.detail.result) {
			isLoading = true;
			if(router.params.tipoCaso == 'terceiros' || router.params.tipoCaso == 'indenizacao'){
				const result = await PicturesSinisterService.resetPictureDocumentosAuxiliares(router.params.tipoCaso, currentPhotoTypeToReset, router.params.sinisterCode);
				
				isLoading = false;

				if (result) {
					isPopupResetPhotosSuccessOpen = false;
					isPopupResetPhotosSuccessOpen = true;
				} else {
					isPopupResetPhotosErrorOpen = false;
					isPopupResetPhotosErrorOpen = true;
				}
				
				loadPicturesStatus(router.params.contract);
			}else{
				const result = await PicturesSinisterService.resetPicture(currentPhotoTypeToReset, $contract.contractNumber, router.params.sinisterCode);
	
				isLoading = false;
	
				if (result) {
					isPopupResetPhotosSuccessOpen = false;
					isPopupResetPhotosSuccessOpen = true;
				} else {
					isPopupResetPhotosErrorOpen = false;
					isPopupResetPhotosErrorOpen = true;
				}
				
				loadPicturesStatus(router.params.contract);
			}
		}
	}

	async function resetPhotos(e) {
		if (e.detail.result) {
			isLoading = true;

			if(router.params.tipoCaso == 'terceiros' || router.params.tipoCaso == 'indenizacao'){
				const result = await PicturesSinisterService.resetPicturesDocumentosAuxiliares(router.params.tipoCaso, router.params.sinisterCode);

				isLoading = false;

				if (result) {
					isPopupResetPhotosSuccessOpen = false;
					isPopupResetPhotosSuccessOpen = true;
				} else {
					isPopupResetPhotosErrorOpen = false;
					isPopupResetPhotosErrorOpen = true;
				}

				loadPicturesStatus(router.params.contract);
			}else{
				const result = await PicturesSinisterService.resetPictures($contract.contractNumber, router.params.sinisterCode);
	
				isLoading = false;
	
				if (result) {
					isPopupResetPhotosSuccessOpen = false;
					isPopupResetPhotosSuccessOpen = true;
				} else {
					isPopupResetPhotosErrorOpen = false;
					isPopupResetPhotosErrorOpen = true;
				}
	
				loadPicturesStatus(router.params.contract);
			}
		}
	}

	onMount(async () => {
		isLoading = true;
		const result = await setContract(router.params.contract);

		if (result) {
			loadPicturesStatus(router.params.contract);
			return;
		}

		isLoading = false;
		navigateTo('/busca-contrato');
	});

	onDestroy(() => {
		window['currentPictureName'] = false;
	});
</script>

<Menu />

<Popup
	type="prompt"
	visible={isPopupResetCurrentOpen}
	title="Apagar essa foto?"
	message="Você está prestes a apagar essa foto. Tem certeza disso?"
	on:prompt={resetCurrent} />
<Popup
	type="prompt"
	visible={isPopupResetPhotosOpen}
	title="Apagar todas as fotos?"
	message={router.params.tipoCaso == 'terceiros' || router.params.tipoCaso == 'indenizacao' ? "Você está prestes a apagar todas as fotos desse caso. Tem certeza disso?" : "Você está prestes a apagar todas as fotos desse contrato. Tem certeza disso?"}
	on:prompt={resetPhotos} />
<Popup
	visible={isPopupResetPhotosSuccessOpen}
	title="Foto(s) Apagada(s) com sucesso"
	message="Operação concluída com sucesso." />
<Popup
	visible={isPopupResetPhotosErrorOpen}
	title="Erro ao apagar foto(s)"
	message="Não foi possível apagar a(s) foto(s) devido a um erro de servidor." />

<Loader visible={isLoading} />

<main>
	{#if pictures.length > 0}
		<div transition:fade>
			<CheckCarouselSinister tipoCaso={router.params.tipoCaso} pictures={pictures} on:approval={approve} on:reproval={reprove} on:resetAll={promptResetPhotos} on:resetCurrent={promptResetCurrent} />
		</div>
	{/if}
</main>

<Footer />