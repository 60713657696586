import { api_auth, URLS } from "../helpers/Request";

export default {
  async getOpenTheft() {
    try {
      return await api_auth.get(`${URLS.FURTO_ROUBO}/em-aberto`).json();
    } catch (e) {
      console.error(e);
      return false;
    }
  },
}
