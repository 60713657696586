<script>
  import { onMount, onDestroy } from 'svelte';
  import { Link, navigateTo } from 'svero';
  import jwt_decode from 'jwt-decode';
  import Menu from '../components/Menu.svelte';
  import Popup from '../components/Popup.svelte';
  import Loader from '../components/Loader.svelte';
  import Map from '../components/Map.svelte';
  import MapSidebar from '../components/MapSidebar.svelte';
  import MapHeader from '../components/MapHeader.svelte';
  import TravelsService from '../services/Travels';
  import { contract } from '../stores/Contract';
  import { vehicle } from '../stores/Vehicle';
  import { setContract } from '../helpers/SetContract';

  export let router = {};
  let isMapReady = false;
  let isJWTError = false;
  let isTokenValid = true;
  let isTokenExpired = true;
  let isLoading = false;
  let map;
  let expiresIn = 0;
  let lastCommunicationTimeout;
  let currentMarker;
  let position = {};

  async function loadBaseData() {
    const result = await TravelsService.getCurrentPosition($contract.contractNumber);

    if (!result) {
      $vehicle.position = {
        error: true,
      };
      isLoading = false;
      return;
    }

    if ($vehicle.position && $vehicle.position.ts === result.ts) {
      lastCommunicationTimeout = setTimeout(() => {
        loadBaseData();
      }, 3000);
      return;
    }

    $vehicle.position = result;
    $vehicle = $vehicle;

    setTimeout(() => {
      if (currentMarker) {
        updateMarkerPosition();
      }
    }, 100);
    
    lastCommunicationTimeout = setTimeout(() => {
      loadBaseData();
    }, 3000);
  }

  function parseToken() {
    try {
      const token = jwt_decode(router.params.token);
      expiresIn = token.exp * 1000;
      isTokenValid = true;
      return token.exp * 1000 < +new Date();
    } catch (e) {
      console.warn(e);
      isJWTError = true;
      return true;
    }
  }

  function handleJWTPopupError() {
    navigateTo('/login');
    isJWTError = false;
  }

  function updateMarkerPosition() {
    currentMarker.setPosition({ lat: $vehicle.position.lat, lng: $vehicle.position.long });
  }

  function setMap(payload) {
    isMapReady = true;
    isLoading = false;
    map = payload.detail.map;
    loadMarker();
  }

  async function loadMarker() {
    let iconUrl;

    if ($vehicle.position.ign) {
      iconUrl = '/img/icon-map-car-on.svg';
    } else {
      iconUrl = '/img/icon-map-car.svg';
    }

    currentMarker = new google.maps.Marker({
      position: {lat: $vehicle.position.lat, lng: $vehicle.position.long},
      map,
      icon: {
        url: iconUrl,
      }
    });
  }

  onMount(async () => {
    if (window.alreadyRanOnMount) {
      return;
    } else {
      window.alreadyRanOnMount = true;
    }

    if (!router.params.token) {
      isJWTError = true;
      return;
    }

    isTokenExpired = parseToken();

    if (isJWTError) {
      const popup = new Popup({
        target: document.body,
        props: {
          visible: true,
          title: 'Token Inválido',
          message: 'O token de autenticação é inválido, por favor efetue o login ou peça um novo token para Loovi.'
        }
      });
      popup.$on('prompt', () => {
        window.location = '/login';
      });
      return;
    }

    if (isTokenExpired) {
      const popup = new Popup({
        target: document.body,
        props: {
          visible: true,
          title: 'Mapa Temporário Expirado',
          message: 'Esse mapa temporário expirou, por favor peça um novo link para a Loovi.'
        }
      });
      popup.$on('prompt', () => {
        window.location = '/login';
      });
      return;
    }
    
    isLoading = true;
    const result = await setContract(router.params.contract, true, router.params.token);
    
    if (!$contract || !$contract.imei) {
      isLoading = false;
      const popup = new Popup({
        target: document.body,
        props: {
          visible: true,
          title: 'Contrato não possui Imei',
          message: 'Não é possível carregar mapa de um contrato que não possui Imei.'
        }
      });
      popup.$on('prompt', () => {
        window.location = '/busca-contrato';
      });
      return;
    }

    await loadBaseData();
    isLoading = false;

    setTimeout(() => {
      updateMarkerPosition();
    }, 3000);
  });

  onDestroy(() => {
    clearTimeout(lastCommunicationTimeout);
  });
</script>

<Loader visible={isLoading} />

{#if isTokenValid && !isTokenExpired && !isLoading}
  <Menu showNav={false} isTemporary={true} expiresIn={expiresIn} />
  <main>
    <MapHeader />
    <Map on:ready={setMap} lat={$vehicle.position.lat} long={$vehicle.position.long} />
    <MapSidebar isMapReady={isMapReady} map={map} isTemporary={true} />
  </main>
{/if}

<style>
  main {
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    flex-direction: column;
    position: relative;
    padding-top: 50px;
  }
</style>