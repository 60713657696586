<script>
	import { createEventDispatcher } from 'svelte';
	import Popup from './Popup.svelte';

	const dispatch = createEventDispatcher();

	export let type = 'default';
	export let subtitle = 'Ação';
	export let title = 'Substituição de Dispositivo';
	export let fireLabel = 'Disparar Ação';
	export let hasText = false;
	export let textPlaceholder = '';
	export let text = '';
	export let isPopupOpen = false;

	function openConfirmation() {
		isPopupOpen = false;
		isPopupOpen = true;
	}

	function handleApproval(e) {
		if (e.detail.result) {
			dispatch('fire', {
				text: text
			});
		}
	}
</script>

<style>
	.trigger {
		padding: 14px 12px;
		border-radius: 4px;
		background: var(--color-blue-dark-05);
		border: 1px solid var(--color-blue-dark-30);
	}

	.trigger--success {
		border-color: var(--color-status-success-30);
		background: var(--color-status-success-05);
	}

	.trigger--success .trigger__text h4 {
		color: var(--color-status-success);
	}

	.trigger--success .trigger__text h3 {
		color: var(--color-status-success);
	}

	.trigger--error {
		border-color: var(--color-status-error-30);
		background: var(--color-status-error-05);
	}

	.trigger--error .trigger__text h4 {
		color: var(--color-status-error);
	}

	.trigger--error .trigger__text h3 {
		color: var(--color-status-error);
	}

	.trigger + :global(.trigger) {
		margin-top: 15px;
	}

	.trigger__row {
		display: flex;
		align-items: center;
	}

	.trigger__text {
		flex: 1;
	}

	.trigger__text h4 {
		font-size: 1.2rem;
		margin-bottom: 3px;
		color: var(--color-blue-dark);
	}

	.trigger__text h3 {
		font-size: 1.5rem;
		font-weight: bold;
		color: var(--color-blue-dark);
	}

	.trigger .field {
		width: 100%;
		height: 90px;
		margin-top: 13px;
		resize: none;
	}
</style>

<div
	class="trigger"
	class:trigger--success={type === 'secure'}
	class:trigger--error={type === 'robbed'}>
	<div class="trigger__row">
		<div class="trigger__text">
			<h4>{subtitle}</h4>
			<h3>{title}</h3>
		</div>

		<button
			class="btn"
			on:click={openConfirmation}
			class:btn--success={type === 'secure'}
			class:btn--error={type === 'robbed'}>
			{fireLabel}
		</button>
	</div>
	{#if hasText}
		<div class="trigger__row">
			<textarea class="field" bind:value={text} placeholder={textPlaceholder} />
		</div>
	{/if}

	<Popup
		visible={isPopupOpen}
		type="prompt"
		on:prompt={handleApproval}
		title={`Disparar ${subtitle}`}
		message={`Tem certeza de que deseja disparar o comando: <b>"${title}"</b>?`} />
</div>
