import Popup from '../components/Popup.svelte';

export default class PopupHelper {
	static alert(title, message) {
		const popup = new Popup({
			target: document.body,
			props: {
				visible: true,
				title: title,
				message: message
			}
		});
	}
}
