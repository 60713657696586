<script>
  import Heading from '../components/Heading.svelte';
  import Loader from '../components/Loader.svelte';
  import Popup from '../components/Popup.svelte';
  import EventsService from '../services/Events';
  import { contract } from '../stores/Contract';

  let isPopupFurtoRoubo = false;
  let isFurtoRoubo = false;
  let isEmergencyMode = false;
  let isLoading = false;

  function firePopupFurtoRoubo() {
    isPopupFurtoRoubo = false;
    isPopupFurtoRoubo = true;
  }

  async function handleFurtoRoubo(e) {
    if (e.detail.result) {
      const result = await EventsService.enableTheft($contract.contractNumber);

      if (result) {
        isFurtoRoubo = true;
      } else {
        PopupHelper.alert('Erro de Servidor', result.error);
      }
    }
  }

  async function enableEmergencyMode() {
    isLoading = false;
    isLoading = true;
    const result = await EventsService.enableEmergencyMode($contract.contractNumber);

    if (result) {
      isEmergencyMode = true;
      isLoading = false;
    }
  }
</script>

<Loader visible={isLoading} />

<Popup type="prompt" visible={isPopupFurtoRoubo} title="Disparar Furto e Roubo?" message="Essa ação só deve ser utilizada em casos confirmados. Tem certeza que deseja dispará-la?" on:prompt={handleFurtoRoubo} />

<div class="map-header">
  <div class="container">
    <div class="map-header__col">
      <Heading main="Mapa do Veículo" />
      {#if isFurtoRoubo}
        <div class="info info--error">
          Modo Furto e Roubo <b>Ativado</b>.
        </div>
      {/if}
      {#if isEmergencyMode}
        <div class="info info--error">
          Modo Emergência <b>Ativado</b>.
        </div>
      {/if}
    </div>
    <div class="map-header__col">
      
    </div>
  </div>
</div>

<style>
  .info {
    margin-left: 10px;
    flex: 0;
  }

  .map-header {
    position: relative;
    z-index: 3;
    height: 64px;
    background: #fff;
    box-shadow: 0 2px 4px var(--color-black-10);
  }

  @media screen and (max-width: 600px) {
    .map-header {
      height: auto;
    }
  }

  .map-header .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .map-header__col {
    display: flex;
    align-items: center;
  }

  .map-header__col--full {
    flex: 1;
  }

  .map-header .container .field {
    width: 350px;
    margin-left: 50px;
  }
</style>