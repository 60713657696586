<script>
  import { onMount, tick, setContext } from 'svelte';
  import Menu from '../components/Menu.svelte';
  import Heading from '../components/Heading.svelte';
  import Footer from '../components/Footer.svelte';
  import Loader from '../components/Loader.svelte';
  import SideJson from '../components/SideJson.svelte';
  import SendCommand from '../components/SendCommand.svelte';
  import { NOW, DAY, HOUR, toYMD, toDMYWithHoursAndSeconds, toDMY } from '../helpers/Time';
  import { contract } from '../stores/Contract';
  import { pagination } from '../stores/Pagination';
  import EventsService from '../services/Events';
  import { setContract } from '../helpers/SetContract';
  import DatePickerRange from '../components/DatePickerRange.svelte';
  import Pagination from '../components/Pagination.svelte';

  export let router = {};

  let from = NOW;
  let to = NOW;
  $: fromStart = Math.round(+new Date(`${toYMD(from)}T00:00:00`) / 1000);
  $: toEnd = Math.round(+new Date(`${toYMD(to)}T23:59:59`) / 1000);
  let isLoading = false;
  let events = [];
  let isJsonOpen = false;
  let json = '';
  let startRequests = false;
  let forceReload = false;

  async function paginationInitialLoad(amount) {
    return await EventsService.getEvents($contract.contractNumber, amount);
  }

  async function paginationChunkLoad(amount, nextPageToken) {
    return await EventsService.getEventsNextPage($contract.contractNumber, amount, nextPageToken);
  }

  async function filterEvents() {
    isLoading = true;
    forceReload = true;

    await tick();

    setTimeout(() => {
      forceReload = false;
      startRequests = true;
    }, 1);
  }

  function openJsonView(data) {
    isJsonOpen = false;
    isJsonOpen = true;
    json = JSON.stringify(data, null, 2);
  }

  async function updateFromTo(e) {
    from = e.detail.valueStart;
    to = e.detail.valueEnd;

    await tick();

    $pagination.from = fromStart;
    $pagination.to = toEnd;
  }

  async function handlePageChange(e) {
    await tick();

    if (e.detail[0]) {
      console.log(new Date(e.detail[0].DataHora * 1000).toISOString());
    }
    events = e.detail;
  }

  setContext('pagination_initial_load', paginationInitialLoad);
  setContext('pagination_chunk_load', paginationChunkLoad);

  onMount(async () => {
    isLoading = true;
    await setContract(router.params.contract);
    isLoading = false;
    $pagination.from = fromStart;
    $pagination.to = toEnd;
  });
</script>
    
<Menu />

<SideJson visible={isJsonOpen} json={json} />

<main>
  <div class="container">
    <Heading main="Eventos" />

    <div class="panel">
      <div class="panel__head">
        <h2 class="panel__head__title">
          Ações Disponíveis
        </h2>
      </div>
      <div class="panel__row">
        <div class="panel__forms panel__forms--full">
          <div class="panel__forms__col">
            <label class="panel__forms__label">Período:</label>
            <div class="panel__forms__value">
              <DatePickerRange maxDays={7} startWithSingleDay={true} on:change={updateFromTo} />
            </div>
          </div>
          <div class="panel__forms__col">
            <label class="panel__forms__label">&nbsp;</label>
            <div class="panel__forms__value">
              <button class="btn" on:click={filterEvents}>Filtrar</button>
            </div>
          </div>
        </div>
      </div>
      <div class="panel__row panel__row--vertical">
        <table class="grid">
          <thead>
            <tr>
              <th width="15%">Tipo</th>
              <th width="5%">GPS</th>
              <th width="15%">Data e Hora</th>
              <th width="15%">Usuário</th>
              <th width="15%">IMEI</th>
              <th width="10%">Chassi</th>
              <th width="7%" aria-label="Quilometragem" data-balloon-pos="down-left">Km</th>
              <th width="7%" aria-label="Velocidade" data-balloon-pos="down-left">Km/h</th>
              <th width="5%">Mapa</th>
              <th width="5%">JSON</th>
            </tr>
          </thead>
        </table>
        <div class="grid__scroller">
          {#if isLoading}
            <Loader local={true} visible={isLoading} />
          {:else}
            {#if events.length > 0}
            <table class="grid">
              <tbody>
                {#each events as event}
                <tr>
                  <td width="15%" class:grid__status--success={true} class:grid__status--error={event.Tipo.includes('erro-') || event.Tipo.includes('erros-')} class:grid__status--info={event.Tipo.includes('comando-')}>{event.Tipo}</td>
                  <td width="5%" class:grid__status--success={event.SinalGps === true} class:grid__status--error={event.SinalGps === false}>
                    {#if event.SinalGps === true}
                      <span aria-label="Sinal de GPS: Preciso" data-balloon-pos="right">GPS</span>
                    {:else if event.SinalGps === false}
                      <span aria-label="Sinal de GPS: Impreciso" data-balloon-pos="right">GPS</span>
                    {:else}
                      <span>N/A</span>
                    {/if}
                  </td>
                  <td width="15%">{toDMYWithHoursAndSeconds(event.DataHora)}</td>
                  <td width="15%">{event.Contrato || '--'}</td>
                  <td width="15%">{event.Imei || '--'}</td>
                  <td width="10%" class="cell--small">{event.Chassi || '--'}</td>
                  <td width="7%">{event.Quilometragem || '-- '}km</td>
                  <td width="7%">{event.Velocidade | '-- '}km/h</td>
                  <td width="5%">
                    <a class="grid__btn" href="https://www.google.com.br/maps/search/?api=1&query={event.Latitude},{event.Longitude}" target="_blank">
                      <img src="/img/icon-map.svg" alt="">
                    </a>
                  </td>
                  <td width="5%">
                    <button class="grid__btn" on:click={() => openJsonView(event)}>
                      <img src="/img/icon-code.svg" alt="">
                    </button>
                  </td>
                </tr>
                {/each}
              </tbody>
            </table>
            {:else}
              <div class="grid__empty">Nenhum evento encontrado neste período.</div>
            {/if}
          {/if}
        </div>
        {#if startRequests && !forceReload}
          <Pagination on:change={handlePageChange} on:loading={(e) => isLoading = e.detail} />
        {/if}
      </div>
    </div>

    <SendCommand />
  <div>
</main>

<Footer />

<style>
  .panel__forms__value {
    width: 200px;
  }

  .panel__forms__value :global(input) {
    width: 100%;
  }

  .grid__scroller {
    max-height: calc(100vh - 295px - 100px);
  }

  .cell--small {
    font-size: 11px;
    line-break: anywhere;
    padding-right: 10px;
    line-height: 13px;
  }
</style>
