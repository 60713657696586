<script>
  import { onMount } from 'svelte';
  import { navigateTo } from 'svero';
	import Menu from '../components/Menu.svelte';
	import Heading from '../components/Heading.svelte';
	import Footer from '../components/Footer.svelte';
	import Loader from '../components/Loader.svelte';
	import Popup from '../components/Popup.svelte';
	import Trigger from '../components/Trigger.svelte';
	import { contract } from '../stores/Contract';
	import EventsService from '../services/Events';
  import PopupHelper from '../helpers/Popup';
  import { setContract } from '../helpers/SetContract';

	export let router = {};
	let isLoading = true;
	let isTheft = false;
	let isEmergencyMode = false;
	let phone = '';

	async function handleEnableTheft() {
		isLoading = true;
		const result = await EventsService.enableTheft($contract.contractNumber);
		isTheft = result ? true : false;
		isLoading = false;
	}

	async function handleDisableTheft() {
		isLoading = true;

		const result = await EventsService.disableTheft($contract.contractNumber);

		if (result) {
			isTheft = false;
			isEmergencyMode = false;
		} else {
			isTheft = true;
		}

		isLoading = false;
	}

	async function handleEnableEmergencyMode() {
		isLoading = true;
		const result = await EventsService.enableEmergencyMode($contract.contractNumber);
		isEmergencyMode = result ? true : false
		isLoading = false;
	}

	async function handleUrlSend() {
		isLoading = true;
		const result = await EventsService.sendTemporaryLink($contract.contractNumber, phone);

		result ? PopupHelper.alert('SMS Enviado!', `O SMS foi enviado com sucesso para o número <b>${phone}</b>.`) :
		PopupHelper.alert('SMS Não Enviado!', `Erro de servidor ao enviar SMS. Verifique o número e tente novamente`)

		isLoading = false;
	}

	onMount(async () => {
    isLoading = true;
    const result = await setContract(router.params.contract);
    if (!result) return;
		const resultTheft = await EventsService.isTheft(router.params.contract);

		if (resultTheft) {
			isTheft = !!resultTheft.Furto;
      isEmergencyMode = !!resultTheft.DataHoraModoEmergencia;
      isLoading = false;
		} else {
      isLoading = false;
    }

	});
</script>

<style>
	.info {
		margin-bottom: 10px;
	}

	.panel__row {
		padding: 11px 13px;
	}

	.panel__forms__value {
		position: relative;
	}

	.panel__forms__value .field {
		width: 100%;
	}

	.actions {
		position: absolute;
		right: 0;
		height: 34px;
		display: flex;
		align-items: center;
		border-left: 1px solid var(--color-black-30);
		padding: 5px;
	}

	.actions__btn__sms {
		font-size: 12px;
		color: #fff;
		text-align: center;
		padding: 4px 10px;
		background: var(--color-status-success);
		border: none;
		border-radius: 2px;
	}

	.field--command {
		padding-right: 130px;
	}
</style>

<Menu />

<Loader visible={isLoading} />

<main>
	<div class="container">
		<Heading main="Furto e Roubo" />

		<div class="panel">
			<div class="panel__head">
				<h2 class="panel__head__title">Comandos Disponíveis</h2>
			</div>
			<div class="panel__row">
				<div class="panel__row__col">
					<div class="panel__subhead">
						<img class="panel__subhead__icon" src="/img/icon-chip.svg" alt="" />
						<div class="panel__subhead__title">Dados do Dispositivo e Disparar Comandos</div>
					</div>
					<div class="panel__forms">
						<div class="panel__forms__col">
							<div class="panel__forms__label">Nome</div>
							<div class="panel__forms__value">{$contract.clientName}</div>
							<div class="panel__forms__label">Veículo</div>
							<div class="panel__forms__value">{$contract.vehicle || '--'}</div>
						</div>
						<div class="panel__forms__col">
							<div class="panel__forms__label">Placa</div>
							<div class="panel__forms__value">{($contract.plate || '').toUpperCase()}</div>
							<div class="panel__forms__label">IMEI</div>
							<div class="panel__forms__value">{$contract.imei}</div>
						</div>
					</div>
					<div class="panel__body panel__body--p10">
						{#if isTheft}
							<div class="info info--error">
								<b>Modo Furto e Roubo Ativado!</b>
							</div>
						{/if}
						{#if isEmergencyMode}
							<div class="info info--error">
								<b>Modo de Emergência ativado!</b>
							</div>
						{/if}
						{#if isTheft}
							<Trigger
								on:fire={handleDisableTheft}
								type="secure"
								subtitle="Comando"
								title="Desativar Furto e Roubo"
								fireLabel="Desativar Furto e Roubo" />
							{#if !isEmergencyMode}
								<Trigger
									on:fire={handleEnableEmergencyMode}
									type="robbed"
									subtitle="Comando"
									title="Habilitar Modo de Emergência"
									fireLabel="Disparar Modo Emergência" />
							{/if}
						{:else}
							<Trigger
								on:fire={handleEnableTheft}
								type="robbed"
								subtitle="Comando"
								title="Configuração Veículo Roubado (Comunicação em Tempo Real)"
								fireLabel="Disparar Furto e Roubo" />
						{/if}
					</div>
				</div>
			</div>
			{#if isTheft}
				<div class="panel__row">
					<div class="panel__forms__col panel__forms__col--1">
						<label class="panel__forms__label">Enviar URL por SMS:</label>
						<div class="panel__forms__value">
							<input
								type="text"
								bind:value={phone}
								class="field field--grey field--command"
								placeholder="Digite o número de telefone móvel" />
							<div class="actions">
								<button class="actions__btn__sms" on:click={handleUrlSend}>Enviar URL</button>
							</div>
						</div>
					</div>
				</div>
			{/if}
		</div>
		<div />
	</div>
</main>

<Footer />
