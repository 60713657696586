import { api_auth, URLS, api_auth_status, api_keyed } from '../helpers/Request';

export default {
	async getPictures(contract, sinisterCode) {
		try {
			const data = await api_auth.get(`${URLS.FOTOS}/${contract}/${sinisterCode}`).json();

			if (data === false) {
				return data;
			}

			if (data.TipoErro) return false;
			return data;
		} catch (e) {
			console.error(e);
			return false;
		}
	},
	async getPicturesDocumentosAuxiliares(tipoCaso, sinisterCode) {
		try {
			const data = await api_keyed.get(`${URLS.DOCUMENTOS_AUXILIARES}/fotos/${tipoCaso}/${sinisterCode}`).json();

			if (data === false) {
				return data;
			}

			if (data.TipoErro) return false;
			return data;
		} catch (e) {
			console.error(e);
			return false;
		}
	},
	async getPictureUrl(pictureTypeId, contract, sinisterCode) {
		try {
			const data = await api_auth.get(`${URLS.FOTOS}/url-download/${contract}/${pictureTypeId}/${sinisterCode}`).json();

			if (data === false) {
				return data;
			}

			if (data.TipoErro) return false;
			return data;
		} catch (e) {
			console.error(e);
			return false;
		}
	},
	async getPictureUrlDcoumentosAuxiliares(sinisterCode, tipoCaso, pictureTypeId) {
		try {
			const data = await api_auth.get(`${URLS.DOCUMENTOS_AUXILIARES}/url-download/${sinisterCode}/${tipoCaso}/${pictureTypeId}`).json();

			if (data === false) {
				return data;
			}

			if (data.TipoErro) return false;
			return data;
		} catch (e) {
			console.error(e);
			return false;
		}
	},
	async approvePicture(pictureTypeId, contract, sinisterCode) {
		try {
			await api_auth.put(`${URLS.FOTOS}/aprova/${contract}/${pictureTypeId}/${sinisterCode}`);
			return true;
		} catch (e) {
			console.error(e);
			return false;
		}
	},
	async approvePictureDocumentosAuxiliares(tipoCaso, sinisterCode, pictureTypeId) {
		try {
			await api_auth_status.put(`${URLS.DOCUMENTOS_AUXILIARES}/aprova/${tipoCaso}/${sinisterCode}/${pictureTypeId}`);
			return true;
		} catch (e) {
			console.error(e);
			return false;
		}
	},
	async reprovePicture(contract, pictureTypeId, sinisterCode, motivoReprovacao) {
		try {
			await api_auth.put(`${URLS.FOTOS}/reprova/${contract}/${pictureTypeId}/${sinisterCode}?motivo-reprovacao=${motivoReprovacao}`);
			return true;
		} catch (e) {
			console.error(e);
			return false;
		}
	},
	async reprovePictureDocumentosAuxiliares(tipoCaso, sinisterCode, pictureTypeId, motivoReprovacao) {
		try {
			await api_auth_status.put(`${URLS.DOCUMENTOS_AUXILIARES}/reprova/${tipoCaso}/${sinisterCode}/${pictureTypeId}/${motivoReprovacao || null}`);
			return true;
		} catch (e) {
			console.error(e);
			return false;
		}
	},
	async resetPictures(contract, sinisterCode) {
		try {
			await api_auth.post(`${URLS.FOTOS}/status/reset/${contract}/${sinisterCode}`);
			return true;
		} catch (e) {
			console.error(e);
			return false;
		}
	},
	async resetPicturesDocumentosAuxiliares(tipoCaso, sinisterCode) {
		try {
			await api_auth_status.post(`${URLS.DOCUMENTOS_AUXILIARES}/status/reset/${tipoCaso}/${sinisterCode}`);
			return true;
		} catch (e) {
			console.error(e);
			return false;
		}
	},
	async resetPicture(pictureTypeId, contract, sinisterCode) {
		try {
			await api_auth.post(`${URLS.FOTOS}/status/reset/foto/${contract}/${pictureTypeId}/${sinisterCode}`);
			return true;
		} catch (e) {
			console.error(e);
			return false;
		}
	},
	async resetPictureDocumentosAuxiliares(tipoCaso, pictureTypeId, sinisterCode) {
		try {
			await api_auth_status.post(`${URLS.DOCUMENTOS_AUXILIARES}/status/reset/${tipoCaso}/foto/${pictureTypeId}/${sinisterCode}`);
			return true;
		} catch (e) {
			console.error(e);
			return false;
		}
	},
	async getContractsWithPendingPictures(amount, page) {
		try {
			if (page) {
				return await api_auth.get(`${URLS.FOTOS}/status/pendentes/${amount}/${page}`).json();
			} else {
				return await api_auth.get(`${URLS.FOTOS}/status/pendentes/${amount}`).json();
			}
		} catch (e) {
			console.error(e);
			return false;
		}
	},
	async uploadPicture(body, contract, pictureTypeId, formato, sinisterCode){
		try {
			const data = await api_auth.put(`${URLS.FOTOS}/upload/${contract}/${pictureTypeId}/${formato}/${sinisterCode}`, {
				headers: {
					'Content-Type': 'text/plain'
				},
				body: body
			});

			if (data === false) {
				return data;
			}

			if (data.TipoErro) return false;
			return data;
		} catch (e) {
			console.error(e);
			return false;
		}
	},
	async uploadPictureDocumentosAuxiliares(body, tipoCaso, pictureTypeId, formato, sinisterCode){
		try {
			const data = await api_auth.put(`${URLS.DOCUMENTOS_AUXILIARES}/upload/${sinisterCode}/${tipoCaso}/${pictureTypeId}/${formato}`, {
				headers: {
					'Content-Type': 'text/plain'
				},
				body: body
			});

			if (data === false) {
				return data;
			}

			if (data.TipoErro) return false;
			return data;
		} catch (e) {
			console.error(e);
			return false;
		}
	},
};
