import { api_auth, URLS } from "../helpers/Request";

export default {
  async getLastCommunication(contractOrImei) {
    try {
      return await api_auth.get(`${URLS.COMUNICACAO}/${contractOrImei}`).json();
    } catch (e) {
      return false;
    }
  },
}