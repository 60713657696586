<script>
	import { onMount } from 'svelte';
	import { Link, navigateTo } from 'svero';
	import Menu from '../components/Menu.svelte';
	import Heading from '../components/Heading.svelte';
	import Footer from '../components/Footer.svelte';
	import Popup from '../components/Popup.svelte';
	import Loader from '../components/Loader.svelte';
	import DatePicker from '../components/DatePicker.svelte';
	import SideJson from '../components/SideJson.svelte';
	import PopupHelper from '../helpers/Popup';
	import { contract } from '../stores/Contract';
	import { NOW, DAY, toDMY, toDMYWithHoursAndSeconds } from '../helpers/Time';
	import VehiclesService from '../services/Vehicles';
	import ContractService from '../services/Contract';

	let isLoading = true;
	let isJsonOpen = false;
	let json = '';
	let vehicles = [];

	function openJsonView(data) {
		isJsonOpen = false;
		isJsonOpen = true;
		json = JSON.stringify(data, null, 2);
	}

	async function assignContract(contractId) {
		isLoading = true;
		const contractData = await ContractService.getContract(contractId);

		if (!contractData) {
      isLoading = false;
			return;
		}

		const phoneData = await ContractService.getPhoneData((contractData.Dispositivo || {}).Imei);

		$contract = {
			clientName: contractData.Nome || 'Usuário',
			contractNumber: contractId,
			imei: contractData.Dispositivo.Imei,
			plate: contractData.Veiculo.Placa,
			vehicle: contractData.Veiculo.Modelo,
			picturesStatus: contractData.StatusFotos,
      telefonia: phoneData || {},
      raw: contractData,
		};

		isLoading = false;

		navigateTo('/busca-contrato');
	}

	async function getVehicles() {
		const result = await VehiclesService.getOpenTheft();

		if (!result) {
			PopupHelper.alert(
				'Erro de Servidor',
				'Erro de servidor ao buscar veículos.'
			);
			return;
		}

		vehicles = result;
	}

	onMount(async () => {
		await getVehicles();
		isLoading = false;
	});
</script>

<Menu showNav={!!$contract && !!$contract.imei} />

<SideJson visible={isJsonOpen} {json} />

<main>
	<div class="container">
		<Heading main="Veículos em Furto e Roubo" />

		<div class="panel">
			<div class="panel__head">
				<h2 class="panel__head__title">Lista de Veículos</h2>
			</div>
			<div class="panel__row panel__row--vertical">
        <table class="grid">
          <thead>
            <tr>
              <th width="20%">Contrato</th>
              <th width="20%">Data Inicio</th>
              <th width="20%">Data Modo Emergência</th>
              <th width="10%">Furto</th>
              <th width="15%">URL Rastreamento</th>
              <th width="5%">JSON</th>
              <th width="10%">Posicionar</th>
            </tr>
          </thead>
        </table>
        <div class="grid__scroller">
          {#if isLoading}
            <Loader local={true} visible={isLoading} />
          {:else}
            {#if vehicles.length > 0}
              <table class="grid">
                <tbody>
                  {#each vehicles as vehicle}
                    <tr>
                      <td width="20%">{vehicle.Contrato || '--'}</td>
                      <td width="20%">{toDMYWithHoursAndSeconds(vehicle.DataHoraInicio)}</td>
                      <td width="20%">
                        {toDMYWithHoursAndSeconds(vehicle.DataHoraModoEmergencia)}
                      </td>
                      <td width="10%"
                        class:grid__status--success={!vehicle.Furto}
                        class:grid__status--error={vehicle.Furto}>
                        {vehicle.Furto ? 'Furtado' : 'Não-furtado'}
                      </td>
                      <td width="15%">{vehicle.UrlRastreamento || '--'}</td>
                      <td width="5%">
                        <button
                          class="grid__btn"
                          on:click={() => openJsonView(vehicle)}>
                          <img src="/img/icon-code.svg" alt="" />
                        </button>
                      </td>
                      <td width="10%">
                        <button
                          class="grid__btn"
                          on:click={() => assignContract(vehicle.Contrato)}>
                          <img src="/img/icon-arrow-right.svg" alt="" />
                        </button>
                      </td>
                    </tr>
                  {/each}
                </tbody>
              </table>
            {:else}
              <div class="grid__empty">Nenhum evento encontrado neste período.</div>
            {/if}
          {/if}
        </div>
			</div>
		</div>

	</div>
</main>

<Footer />

<style>
  .grid__scroller {
    max-height: calc(100vh - 295px - 100px);
  }
</style>
