import { writable, get } from 'svelte/store';

const LOCAL_KEY = 'mercurio-admin-contract';

export function getLocalData() {
  return JSON.parse(sessionStorage.getItem(LOCAL_KEY) || JSON.stringify({ contract: false }));
}

function setLocalData(data) {
  return sessionStorage.setItem(LOCAL_KEY, JSON.stringify(data));
}

export function hasContract() {
  return getLocalData().contract;
}

function $contract() {
	const { subscribe, set } = writable(getLocalData());

	return {
    subscribe,
    set(data) {
      data = Object.assign(data, { contract: true });
      setLocalData(data);
      set(data);
    },
    reset() {
      setLocalData({ contract: false, telefonia: {} });
      set({ contract: false, telefonia: {} });
    },
    get() {
      return get(this);
    }
	};
}

export const contract = $contract();