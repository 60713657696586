<script>
  import { onMount, afterUpdate } from 'svelte';
  import { contract } from '../stores/Contract';
  import Popup from './Popup.svelte';

  export let expiresIn;
  let hours = '00';
  let minutes = '00';
  let seconds = '00';
  let interval;
  let isPopupOpen = false;

  function openPopup() {
    isPopupOpen = false;
    isPopupOpen = true;
  }

  function countdown(endDate) {
    let h, m, s;
    
    endDate = new Date(endDate).getTime();

    if (isNaN(endDate)) {
      return;
    }
    
    function calculate() {
      let startDate = new Date();
      startDate = startDate.getTime();
      
      let timeRemaining = parseInt((endDate - startDate) / 1000);
      
      if (timeRemaining >= 0) {
        h = parseInt(timeRemaining / 3600);
        timeRemaining = (timeRemaining % 3600);
        
        m = parseInt(timeRemaining / 60);
        timeRemaining = (timeRemaining % 60);
        
        s = parseInt(timeRemaining);
        
        hours = ("0" + h).slice(-2);
        minutes = ("0" + m).slice(-2);
        seconds = ("0" + s).slice(-2);
      } else {
        return;
      }
    }
    calculate();
    interval = setInterval(calculate, 1000);
  }

  onMount(() => {
    countdown(expiresIn);
  });

  afterUpdate(() => {
    clearInterval(interval);
    countdown(expiresIn);
  });
</script>

<Popup visible={isPopupOpen} title="Mapa Temporário" message="Este mapa possui acesso temporário. Após o tempo restante, o acesso será revogado." />

<div class="contract" on:click={openPopup}>
  Tempo restante: {hours}:{minutes}:{seconds}
  <img src="/img/icon-info-white.svg" alt="">
</div>

<style>
  .contract {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    margin-left: 20px;
    padding: 5px 13px;
    border-radius: 3px;
    background: var(--color-status-error);
    color: #fff;
    cursor: default;
    font-size: 12px;
    font-weight: bold;
    user-select: none;
    cursor: pointer;
  }

  .contract img {
    margin-left: 5px;
  }
</style>