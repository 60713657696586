<script>
  import { Link } from 'svero';
  import Menu from '../components/Menu.svelte';
  import Heading from '../components/Heading.svelte';
  import Footer from '../components/Footer.svelte';
  import Popup from '../components/Popup.svelte';
</script>

<Menu />

<main>
  <div class="not-found-title">
    <h1>Página não encontrada</h1>
    <p>A página que você tentou acessar não existe (<b>{window.location.pathname}</b>).<br>Utilize o menu para navegar pelo sistema.</p>
  </div>
</main>

<Footer />

<style>
  main {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .not-found-title {
    text-align: center;
  }

  h1 {
    font-weight: bold;
    font-size: 3rem;
    color: var(--color-blue-dark);
  }

  p {
    margin-top: 15px;
    font-size: 1.5rem;
    line-height: 1.8rem;
  }
</style>