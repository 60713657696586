<script>
	import { contract } from '../stores/Contract';

	let copiado = false;

	function copy() {
		navigator.clipboard.writeText($contract.contractNumber);

		copiado = true;

		setTimeout(() => {
			copiado = false;
		}, 1500)
	}
</script>

<style>
	.contract {
		display: flex;
		align-items: center;
		margin-left: 20px;
		padding: 5px 13px;
		border-radius: 3px;
		background: #fff;
		font-size: 12px;
		font-weight: bold;
    	color: var(--color-blue);
    	cursor: text;
  }
  
  @media screen and (max-width: 600px) {
    .contract {
      max-width: 160px;
    }
  }

	.contract--red {
		background: var(--color-status-error);
		color: #fff;
		cursor: default;
	}

	.contract img {
		margin-right: 5px;
    cursor: pointer;
    user-select: none;
	}
</style>

<div class="contract" class:contract--red={!$contract.contract} aria-label={copiado ? "Contrato copiado!" : "Clique no ícone para copiar o contrato"} data-balloon-pos="down">
	{#if $contract.contract}
		<img src={copiado ? "/img/icon-check.png" : "/img/icon-copy.png"} on:click={copy} width="20px" height="20px"/>
		<span>{$contract.contractNumber} | {$contract.clientName} | {$contract.raw.Veiculo.Placa || ''}</span>
	{:else}
		<img src="/img/icon-contract-white.svg" />
		<span>Sem Contrato</span>
	{/if}
</div>