<script>
  export let main = '';
  export let child = '';
  export let centered = false;
</script>

<h1 class="title" class:title--centered={centered}>
  {main}
  {#if child}
    <img src="/img/icon-heading-bar.svg" alt="">
    <span>{child}</span>
  {/if}
</h1>

<style>
  .title {
    display: flex;
    align-items: center;
  }

  @media screen and (max-width: 600px) {
    .title {
      margin: 15px 0 15px 10px;
    }
  }

  .title.title--centered {
    text-align: center;
    justify-content: center;
  }

  .title img {
    margin: 0 7px;
  }

  .title span {
    font-weight: 300;
  }

  @media screen and (max-width: 600px) {
    .title {
      font-size: 15px;
    }
  }
</style>