import App from './App.svelte';
import './styles/main.css';
import { initAuth } from './stores/Auth';
import { removeAppCover } from './helpers/AppCover';

window['APP_VERSION'] = "process.env.PACKAGE_VERSION";

function main() {
  new App({
    target: document.body
  });
  initAuth();
  
  removeAppCover();
}

document.addEventListener('DOMContentLoaded', main);