<script>
  import { onDestroy, onMount } from 'svelte';
  import { Link, navigateTo } from 'svero';
  import Menu from '../components/Menu.svelte';
  import Popup from '../components/Popup.svelte';
  import Loader from '../components/Loader.svelte';
  import Map from '../components/Map.svelte';
  import MapSidebar from '../components/MapSidebar.svelte';
  import MapHeader from '../components/MapHeader.svelte';
  import TravelsService from '../services/Travels';
  import { contract } from '../stores/Contract';
  import { vehicle } from '../stores/Vehicle';
  import { setContract } from '../helpers/SetContract';

	export let router = {};

  let isMapReady = false;
  let map;
  let isLoading = true;
  let position = {};
  let lastCommunicationTimeout;
  let currentMarker;
  let isPageActive = true;

  async function loadBaseData() {
    const result = await TravelsService.getCurrentPosition($contract.contractNumber);

    if (!isPageActive) {
      return;
    }

    if (!result) {
      $vehicle.position = {
        error: true,
      };
      isLoading = false;
      return;
    }

    if ($vehicle.position && $vehicle.position.ts === result.ts) {
      lastCommunicationTimeout = setTimeout(() => {
        loadBaseData();
      }, 3000);
      return;
    }

    $vehicle.position = result;
    $vehicle = $vehicle;

    setTimeout(() => {
      if (currentMarker) {
        updateMarkerPosition();
      }
    }, 100);
    
    lastCommunicationTimeout = setTimeout(() => {
      loadBaseData();
    }, 3000);
  }
  
  function setMap(payload) {
    isMapReady = true;
    isLoading = false;
    map = payload.detail.map;
    loadMarker();
  }

  async function loadMarker() {
    let iconUrl;

    if ($vehicle.position.ign) {
      iconUrl = '/img/icon-map-car-on.svg';
    } else {
      iconUrl = '/img/icon-map-car.svg';
    }

    currentMarker = new google.maps.Marker({
      position: {lat: $vehicle.position.lat, lng: $vehicle.position.long},
      map,
      icon: {
        url: iconUrl,
      }
    });
  }

  function updateMarkerPosition() {
    currentMarker.setPosition({ lat: $vehicle.position.lat, lng: $vehicle.position.long });
  }

  onMount(async () => {
    isLoading = true;
    const result = await setContract(router.params.contract);
    
    if (!$contract || !$contract.imei) {
      const popup = new Popup({
        target: document.body,
        props: {
          visible: true,
          title: 'Contrato não possui Imei',
          message: 'Não é possível carregar mapa de um contrato ou imei que não possui Imei.'
        }
      });
      popup.$on('prompt', () => {
        window.location = '/busca-contrato';
      });
      isLoading = false;
      return;
    }

    await loadBaseData();
    isLoading = false;

    setTimeout(() => {
      updateMarkerPosition();
    }, 3000);
  });


onDestroy(() => {
  isPageActive = false;
  clearTimeout(lastCommunicationTimeout);
})
</script>

<Menu />

<Loader visible={isLoading} />

<main>
  <MapHeader />
  {#if !isLoading && $contract.imei}
    <Map on:ready={setMap} lat={$vehicle.position.lat} long={$vehicle.position.long} />
    <MapSidebar isMapReady={isMapReady} map={map} travelId={router.params.travel} />
  {/if}
</main>

<style>
  main {
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    flex-direction: column;
    position: relative;
  }
</style>