<script>
    import { createEventDispatcher } from 'svelte';
    import { fade } from 'svelte/transition';
    import { scale } from '../helpers/Animations';

    const dispatch = createEventDispatcher();
  
    export let visible = false;
    export let title = false;
    export let message = false;
    export let type = 'alert';
    export let okLabel = 'Ok';
    export let cancelLabel = 'Cancelar';
    export let tipoCaso = 'onBoarding';

    let selectedReason = "";
    let msgAlertIsVisible = false;

    function handleSelection(event) {
      selectedReason = event.target.value;
      dispatch('reasonSelected', selectedReason);

      msgAlertIsVisible = false;
    }

    function ok(e) {
      e.preventDefault();
        visible = false;
        dispatch('prompt', {
          result: true
        });
    }

    function okMotivo(e) {
      e.preventDefault();
      if(selectedReason.length > 0){
        visible = false;
        dispatch('prompt', {
          result: true
        });
        selectedReason = "";
      }else{
        msgAlertIsVisible = true;
      }
    }

    function cancel(e) {
      e.preventDefault();
      visible = false;
      dispatch('prompt', {
        result: false
      });

      selectedReason = "";
    }
  </script>
  

  {#if visible}
    <div class="overlay" transition:fade={{duration: 500}}>
      <div class="popup" transition:scale={{duration: 500}}>
        <h1 class="title">{title}</h1>

        {#if type === 'alert'}
          <p class="message">{@html message}</p>
          <div class="popup-btn-list popup-btn-list--center">
            <button class="btn clean" on:click={ok}>{okLabel}</button>
          </div>
        {/if}

        {#if type === 'prompt'}
          <p class="message">{@html message}</p>
        {/if}

        {#if type === 'promptMotivo'}
          <p class="message-reprova">{message}</p>

          <div class="opcoes">
            <label>
              <input type="radio" name="motivo" value="documento-incompleto" on:change={handleSelection}>
              <span>
                Documento incompleto
              </span>
            </label><br>
            <label>
              <input type="radio" name="motivo" value="documento-incorreto" on:change={handleSelection}>
              <span>
                Documento incorreto
              </span>
            </label><br>
            <label>
              <input type="radio" name="motivo" value="documento-vencido" on:change={handleSelection}>
              <span>
                Documento vencido
              </span>
            </label><br>
            <label>
              <input type="radio" name="motivo" value="foco-ou-nitidez" on:change={handleSelection}>
              <span>
                Falta de foco ou nitidez
              </span>
            </label><br>

            {#if tipoCaso == 'onBoarding'}
              <label>
                <input type="radio" name="motivo" value="foto-incompleta" on:change={handleSelection}>
                <span>
                  Foto incompleta
                </span>
              </label><br>
            {/if}

            <label>
              <input type="radio" name="motivo" value="ma-ilumincacao" on:change={handleSelection}>
              <span>
                Má iluminação
              </span>
            </label><br>

            {#if tipoCaso == 'onBoarding'}
              <label>
                <input type="radio" name="motivo" value="motor-desligado" on:change={handleSelection}>
                <span>
                  Motor desligado
                </span>
              </label><br>
              <label>
                <input type="radio" name="motivo" value="nao-contem-placa-e-motor" on:change={handleSelection}>
                <span>
                  Foto precisa conter placa e motor
                </span>
              </label><br>
              <label>
                <input type="radio" name="motivo" value="painel-nao-identificado" on:change={handleSelection}>
                <span>
                  Painel não identificado
                </span>
              </label><br>
            {/if}

            <label>
              <input type="radio" name="motivo" value="placa-divergente" on:change={handleSelection}>
              <span>
                Placa divergente
              </span>
            </label><br>

            {#if tipoCaso == 'onBoarding'}
              <label>
                <input type="radio" name="motivo" value="posicionamento-incorreto-veiculo" on:change={handleSelection}>
                <span>
                  Posicionamento incorreto do veículo
                </span>
              </label><br>
              <label>
                <input type="radio" name="motivo" value="vidros-abaixados" on:change={handleSelection}>
                <span>
                  Vidros abaixados
                </span>
              </label><br>
            {/if}
          </div>

          {#if msgAlertIsVisible}
            <div class="msgAlerta">
              <p>Nenhum motivo selecionado</p>
            </div>
          {/if}
        {/if}

        {#if type != 'alert'}
          <div class="popup-btn-list">
            <button class="btn btn--outline" on:click={cancel}>{cancelLabel}</button>
            <button class="btn" on:click={type == 'promptMotivo' ? okMotivo : ok}>{okLabel}</button>
          </div>
        {/if}

      </div>
    </div>
  {/if}

  <style>
    .opcoes label input:checked + span{
      font-weight: bold;
    }

    .opcoes{
      margin-top: 20px;
      margin-bottom: 30px;
      border-radius: 5px;
      text-align: left;
      background-color: #f2f3f8;
      font-size: 15px;
      padding: 5px;
      width: 21em;
    }

    .opcoes label{
      cursor: pointer;
      margin-top: 40px;
      margin-bottom: 40px;
    }

    .opcoes label span{
      display: inline-block;
      margin-top: 7px;
      margin-bottom: 7px;
      text-align: center;
    }

    .opcoes label span:hover{
      font-weight: bold;
    }

    .overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--color-black-70);
      z-index: var(--z-8);
      /* user-select: none; */
    }

    .popup {
      width: 395px;
      min-height: 100px;
      border-radius: 5px;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      box-shadow: 0 0 3px rgba(0,0,0,0.1);
      /* user-select: none; */
      padding: 15px;
    }

    .title {
      font-size: 1.8rem;
      font-weight: bold;
      color: var(--color-black);
      margin-bottom: 13px;
      margin-top: 10px;
    }

    .message {
      font-size: 1.4rem;
      color: var(--color-black);
      margin: 0;
      margin-bottom: 30px;
      text-align: center;
      padding: 0 10px;
    }

    .message-reprova{
      font-size: 1.4rem;
      color: var(--color-black);
      margin: 0;
      margin-bottom: 5px;
      text-align: center;
      padding: 0 10px;
    }

    .popup-btn-list {
      display: flex;
      justify-content: space-between;
      min-width: 100%;
      border-radius: 0 0 5px 5px;
      overflow: hidden;
    }

    .popup-btn-list.popup-btn-list--center {
      justify-content: center;
    }

    .btn {
      width: 150px;
      font-size: 1.2rem;
      cursor: pointer;
    }
  
    @media screen and (max-width: 480px) {
      .loader-wrapper {
        padding-right: 0;
      }
    }

    .msgAlerta{
      color: red;
      margin-bottom: 25px;
      font-weight: bold;
    }
  </style>