<script>
    import { onMount } from 'svelte';
    import { Link, navigateTo } from 'svero';
    import jwt_decode from 'jwt-decode';
    import Menu from '../components/Menu.svelte';
    import Heading from '../components/Heading.svelte';
    import Footer from '../components/Footer.svelte';
    import Popup from '../components/Popup.svelte';

    export let router = {};
    let isJWTError = false;

    function handleJWTPopupError() {
      navigateTo('/login');
      isJWTError = false;
    }

    function handleToken() {
      let token;
      try {
        token = jwt_decode(router.params.token);
      } catch (e) {
        isJWTError = true;
      }

      setTimeout(() => {
        navigateTo('/busca-contrato');
      }, 2500);
    }


    onMount(() => {
      handleToken();
    });
  </script>
  
  <Menu showNav={false} showActions={false} showContract={false} />

  <Popup visible={isJWTError} on:prompt={handleJWTPopupError} title="Token Inválido" message="O token de autenticação é inválido, por favor utilize o login tradicional." />
  
  <main>
    <div class="auth">
      <h1>Autenticação Automatizada</h1>
      <p>Aguarde enquanto autenticamos você com nossos sistemas.</p>
      <div class="auth-loading">
        <div class="spinner"></div>
        <img src="/img/icon-sap-mercurio.svg" alt="">
      </div>
    </div>
  </main>
  
  <Footer />
  
  <style>
    main {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  
    .auth {
      text-align: center;
    }

    .auth-loading {
      margin-top: 25px;
      position: relative;
    }

    .spinner {
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: 3px;
      margin-top: -9px;
      width: 17px;
      height: 17px;
      border: 2px solid var(--color-black);
      border-right-color: rgba(0,0,0,0);
      animation: spin 1s linear infinite;
      border-radius: 50%;
    }
  
    h1 {
      font-weight: bold;
      font-size: 3rem;
      color: var(--color-blue-dark);
    }
  
    p {
      margin-top: 15px;
      font-size: 1.5rem;
      line-height: 1.8rem;
    }
  </style>