<script>
	import { onDestroy, onMount, tick } from 'svelte';
	import { fade } from 'svelte/transition';
	import { Link, navigateTo } from 'svero';
	import Menu from '../components/Menu.svelte';
	import Heading from '../components/Heading.svelte';
	import Footer from '../components/Footer.svelte';
	import Popup from '../components/Popup.svelte';
	import Loader from '../components/Loader.svelte';
  import PhotoCheck from '../components/PhotoCheck.svelte';
  import CheckCarousel from '../components/CheckCarousel.svelte';
	import PicturesService from '../services/Pictures';
	import ContractService from '../services/Contract';
  import { contract } from '../stores/Contract';
  import { setContract } from '../helpers/SetContract';
import { wait } from '../helpers/Time';

	export let router = {};
	let pictures = [];
	let isLoading = false;
	let isPopupResetPhotosOpen = false;
	let isPopupResetCurrentOpen = false;
	let isPopupResetPhotosSuccessOpen = false;
	let isPopupResetPhotosErrorOpen = false;
	let currentPhotoTypeToReset = "";

	let picDocumentFront;
	let picDocumentBack;
	let picAddress;
	let picSelfie;
	let picClrv;
	let picCarFront;
	let picCarBack;
	let picCarRight;
	let picCarLeft;
	let picCarChassi;
	let picCarEngine;
	let picCarPanel;
	let isValidContract;

	async function handlePictures(pics) {
		pictures = [];
		await tick();
		
		const promises = pics.map(async pic => {
			pic.type = pic.TipoFoto.Id;

			if (pic.Foto) {
				pic.id = (pic.Foto || {}).Id || '';
				pic.url = await PicturesService.getPictureUrl(pic.type, router.params.contract || $contract.contractNumber);
				pic.approved = (pic.Foto || {}).Aprovacao;
				pic.hasPic = pic.Foto !== null;
			}
		});

		await Promise.all(promises);

		// Sempre espera um pouco (mínimo de 1s) antes de chamar
		// restante do código, para dar tempo do Svelte limpar interface.
		await wait(1000);

		pictures = [...pics];
		picDocumentFront = pictures.find(e => e.type === 'rg-cnh-frente');
		picDocumentBack = pictures.find(e => e.type === 'rg-cnh-verso');
		picAddress = pictures.find(e => e.type === 'comprovante-endereco');
		picSelfie = pictures.find(e => e.type === 'selfie');
		picClrv = pictures.find(e => e.type === 'clrv');
		picCarFront = pictures.find(e => e.type === 'veiculo-frente');
		picCarBack = pictures.find(e => e.type === 'veiculo-traseira');
		picCarRight = pictures.find(e => e.type === 'veiculo-lateral-direita');
		picCarLeft = pictures.find(e => e.type === 'veiculo-lateral-esquerda');
		picCarChassi = pictures.find(e => e.type === 'chassi');
		picCarEngine = pictures.find(e => e.type === 'motor');
		picCarPanel = pictures.find(e => e.type === 'quilometragem');
		isLoading = false;
	}

	async function loadPicturesStatus(contractNumber) {
		isLoading = true;
		const picturesData = await PicturesService.getPictures(contractNumber || $contract.contractNumber);

		if (picturesData.error) {
			isLoading = false;
			console.error(picturesData.error);
			return;
		}

		handlePictures(picturesData);
	}

	async function approve(e) {
		const contractNumber = router.params.contract || $contract.contractNumber;
		const pictureTypeId = e.detail.id;
		isLoading = true;
		const result = await PicturesService.approvePicture(pictureTypeId, contractNumber);

		if (result.error) {
			isLoading = false;
			PopupHelper.alert('Erro de Servidor', result.message);
			return;
		}

		loadPicturesStatus(router.params.contract);
	}

	async function reprove(e) {
		const contractNumber = router.params.contract || $contract.contractNumber;
    	const pictureTypeId = e.detail.id;
		const motivoReprovacao = e.detail.selectedReason;

		isLoading = true;
		const result = await PicturesService.reprovePicture(pictureTypeId, contractNumber, motivoReprovacao);

		if (result.error) {
			isLoading = false;
			PopupHelper.alert('Erro de Servidor', result.message);
			return;
		}

		loadPicturesStatus(router.params.contract);
	}

	function promptResetPhotos() {
		isPopupResetPhotosOpen = false;
		isPopupResetPhotosOpen = true;
	}

	function promptResetCurrent(e) {
		currentPhotoTypeToReset = e.detail;
		isPopupResetCurrentOpen = false;
		isPopupResetCurrentOpen = true;
	}

	async function resetCurrent(e) {
		if (e.detail.result) {
			isLoading = true;
			const result = await PicturesService.resetPicture(currentPhotoTypeToReset, $contract.contractNumber);

			isLoading = false;

			if (result) {
				isPopupResetPhotosSuccessOpen = false;
				isPopupResetPhotosSuccessOpen = true;
			} else {
				isPopupResetPhotosErrorOpen = false;
				isPopupResetPhotosErrorOpen = true;
			}
			
			loadPicturesStatus(router.params.contract);
		}
	}

	async function resetPhotos(e) {
		if (e.detail.result) {
			isLoading = true;
			const result = await PicturesService.resetPictures($contract.contractNumber);

			isLoading = false;

			if (result) {
				isPopupResetPhotosSuccessOpen = false;
				isPopupResetPhotosSuccessOpen = true;
			} else {
				isPopupResetPhotosErrorOpen = false;
				isPopupResetPhotosErrorOpen = true;
			}

			loadPicturesStatus(router.params.contract);
		}
	}

	onMount(async () => {
    isLoading = true;
    const result = await setContract(router.params.contract);
    if (result) {
      loadPicturesStatus(router.params.contract);
      return;
    }
    isLoading = false;
    navigateTo('/busca-contrato');
	});

	onDestroy(() => {
		window['currentPictureName'] = false;
	});
</script>

<Menu />

<Popup
	type="prompt"
	visible={isPopupResetCurrentOpen}
	title="Apagar essa foto?"
	message="Você está prestes a apagar essa foto. Tem certeza disso?"
	on:prompt={resetCurrent} />
<Popup
	type="prompt"
	visible={isPopupResetPhotosOpen}
	title="Apagar todas as fotos"
	message="Você está prestes a apagar todas as fotos desse contrato. Tem certeza disso?"
	on:prompt={resetPhotos} />
<Popup
	visible={isPopupResetPhotosSuccessOpen}
	title="Foto(s) Resetada(s) com sucesso"
	message="Operação concluída com sucesso." />
<Popup
	visible={isPopupResetPhotosErrorOpen}
	title="Erro ao apagar foto(s)"
	message="Não foi possível apagar a(s) foto(s) devido a um erro de servidor." />

<Loader visible={isLoading} />

<main>
	{#if pictures.length > 0}
		<div transition:fade>
			<CheckCarousel pictures={pictures} on:approval={approve} on:reproval={reprove} on:resetAll={promptResetPhotos} on:resetCurrent={promptResetCurrent} />
		</div>
	{/if}
</main>

<Footer />
