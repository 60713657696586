<script>
  import { createEventDispatcher } from 'svelte';
  import { toWrittenHoursMinutes } from '../helpers/Time';

  const dispatch = createEventDispatcher();

  export let isActive = false;
  export let dateStart = 'N/A';
  export let dateEnd = 'N/A';
  export let routeA = 'N/A';
  export let routeB = 'N/A';
  export let id = '0';
  export let readonly = false;
  export let dateDiff = '';
  export let maximumVelocity;
  export let mediumVelocity;
  export let totalDistance;

  function activateTravel() {
    if (readonly) {
      return;
    }
    
    isActive = true;
    dispatch('active', { id });
  }

  function preventAction(e) {
    e.preventDefault();
    e.stopPropagation();
    return false;
  }
</script>

<div class="map_travel" class:map_travel--active={isActive} on:click={activateTravel}>
  <div class="map_travel__id" on:click={preventAction}>{id}</div>
  <div class="map_travel__date">
    <span>{dateStart}</span>
    <span>{dateEnd}</span>
  </div>
  <div class="map_travel__img">
    {#if isActive}
    <img src="/img/icon-map-ab-white.svg" alt="">
    {:else}
    <img src="/img/icon-map-ab.svg" alt="">
    {/if}
  </div>
  <div class="map_travel__routes">
    <div class="map_travel__routes__route">{routeA || 'N/A'}</div>
    <div class="map_travel__routes__route">{routeB || 'N/A'}</div>
  </div>
  <div class="map_travel__hour" on:click={preventAction}>
    <div class="map_travel__data">
      <div class="map_travel__data__item">Vel. Máxima: {maximumVelocity}km/h</div>
      <div class="map_travel__data__item">Vel. Média: {mediumVelocity}km/h</div>
    </div>
    <div>Duração: {toWrittenHoursMinutes(dateDiff * 1000)}</div>
    <div>Distância: {totalDistance}km</div>
  </div>
</div>

<style>
  .map_travel {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    border: 1px solid var(--color-blue-dark-40);
    background: var(--color-blue-dark-05);
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    margin-bottom: 10px;
    padding-top: 40px;
    padding-bottom: 80px;
  }

  .map_travel:not(.map_travel--active):hover {
    background: var(--color-blue-dark-10);
  }

  .map_travel--active {
    background: var(--color-blue);
  }

  .map_travel--active .map_travel__hour {
    color: #fff;
  }

  .map_travel__hour > div {
    padding: 2px;
  }

  .map_travel__date {
    width: 34px;
    font-size: 0.9rem;
    color: var(--color-blue);
    font-weight: bold;
    text-align: center;
  }

  .map_travel__date span:first-child {
    display: block;
    margin-bottom: 10px;
  }

  .map_travel--active .map_travel__date {
    color: #fff;
  }

  .map_travel__img {
    margin: 0 8px;
  }

  .map_travel__routes {
    margin-top: -2px;
  }

  .map_travel__routes__route {
    width: 165px;
    font-size: 1.1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--color-blue);
  }

  .map_travel--active .map_travel__routes__route {
    color: #fff;
  }

  .map_travel__routes__route + .map_travel__routes__route {
    margin-top: 19px;
  }

  .map_travel__id {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 20px;
    font-size: 10px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--color-blue);
    color: #fff;
    font-family: monospace;
    font-weight: bold;
    border-radius: 3px 3px 0 0;
    cursor: text;
  }

  .map_travel__hour {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 5px;
    font-size: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--color-blue-15);
    color: var(--color-blue);
    font-weight: bold;
    border-radius: 3px 3px 0 0;
    cursor: text;
    padding-bottom: 10px;
  }

  .map_travel__data {
    display: flex;
    justify-content: space-between;
    padding: 4px;
  }

  .map_travel__data__item {
    padding: 4px;
  }
</style>