import { api, api_auth, api_auth_status, URLS } from '../helpers/Request';

export default {
  async getContractTemporary(contractImei, idToken) {
		try {
      let data = await api.get(`${URLS.DADOS_CLIENTE}/contrato/${contractImei}`,
      {
        headers: {
          'id-token': idToken,
        }
      }).json();

      if (data === false) {
        return data;
      }
      
      if (!data.Veiculo) {
        data.Veiculo = {};
      }

      if (!data.Dispositivo) {
        data.Dispositivo = {};
      }

      return data;
		} catch (e) {
			console.error(e);
			return false;
		}
  },
  
	async getContract(contractImei) {
		try {
      let data = await api_auth_status.get(`${URLS.DADOS_CLIENTE}/contrato/${contractImei}`).json();
      
      if (data === false) {
        return data;
      }
      
      if (!data.Veiculo) {
        data.Veiculo = {};
      }

      if (!data.Dispositivo) {
        data.Dispositivo = {};
      }

      return data;
		} catch (e) {
      console.error(e);
      return false;
		}
	},

	async getContractFromUrl(contractImei) {
		try {
      let data = await api_auth.get(`${URLS.DADOS_CLIENTE}/contrato/${contractImei}`).json();

      if (data === false) {
        return data;
      }
      
      if (!data.Veiculo) {
        data.Veiculo = {};
      }

      if (!data.Dispositivo) {
        data.Dispositivo = {};
      }
      
			return data
		} catch {
			return false;
		}
	},

	async getPhoneDataTemporary(imei, idToken) {
		try {
			return await api.get(`${URLS.TELEFONIA}/${imei}`, {
        headers: {
          'id-token': idToken,
        }
      }).json();
		} catch (e) {
			return false;
		}
  },
  
  async getPhoneData(imei) {
    if(imei){
      try {
        return await api_auth.get(`${URLS.TELEFONIA}/${imei}`).json();
      } catch (e) {
        return false;
      }
    }else{
      return false;
    }
	}
};
