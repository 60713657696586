<footer class="footer">
  <div class="container">
    <div class="rights">
      <img src="/img/logo-blue.png" alt="Mercurio">
      Produto de propriedade Loovi. Todos os direitos reservados. v{window['APP_VERSION']}
    </div>
    <div class="by">
      by
      <img src="/img/logo-loovi.svg" alt="Loovi">
    </div>
  </div>
</footer>

<style>
  .footer {
    display: flex;
    align-items: center;
    height: 62px;
    border-top: 1px solid var(--color-black-15);
    background: var(--color-black-05);
    color: var(--color-blue-dark);
    margin-top: 50px;
  }

  .footer .container {
    flex: 1;
    display: flex;
    justify-content: space-between;
  }

  .footer .rights {
    display: flex;
    align-items: center;
  }

  .footer .rights img {
    width: 108px;
    margin-right: 10px;
  }

  .footer .by {
    display: flex;
    align-items: center;
  }

  .footer .by img {
    margin-left: 10px;
  }
</style>