import haversine from 'haversine';

export function generateDistanceAndVelocity(routes) {
  let mediumVelocity = 0;
  let maximumVelocity = 0;
  let distance = 0;
  const startTime = routes[0].ts;
  const endTime = routes[routes.length - 1].ts;
  const travelTime = endTime - startTime;

  routes.forEach((route, i) => {
    if (i < routes.length - 1) {
      distance += haversine(
        {
          latitude: routes[i].lat,
          longitude: routes[i].long,
        },
        {
          latitude: routes[i + 1].lat,
          longitude: routes[i + 1].long,
        }
      );
    }
  });
  
  maximumVelocity = Math.round(Math.max(...(routes.map(route => route.vel))));
  mediumVelocity = Math.round(+(distance / (travelTime / 60 / 60)).toFixed(2));
  distance = Math.round(distance);

  return {
    distance: distance,
    maximumVelocity: maximumVelocity,
    mediumVelocity: mediumVelocity,
  }
}