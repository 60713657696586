<script>
    import { fade } from 'svelte/transition';
    import { scale } from '../helpers/Animations.js';
  
    export let visible = false;
    export let local = false;
    export let absolute = false;
  </script>
  
  {#if visible && !local}
  <div class="overlay" transition:fade={{duration: 500}}></div>
  {/if}
  
  {#if visible}
  <div class="loader-wrapper" class:loader-wrapper--local={local} class:loader-wrapper--absolute={absolute} transition:fade={{duration: 500}}>
    <div class="loader" transition:scale>
      <div class="spinner"></div>
    </div>
  </div>
  {/if}
  
  <style>
    .overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: var(--color-black-70);
      z-index: var(--z-9);
      user-select: none;
    }
  
    .loader-wrapper {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: var(--z-10);
      display: flex;
      align-items: center;
      justify-content: center;
      user-select: none;
    }

    .loader-wrapper.loader-wrapper--local {
      position: relative;
      min-height: 100px;
    }

    .loader-wrapper.loader-wrapper--absolute {
      position: absolute;
    }
  
    .loader {
      width: 66px;
      height: 66px;
      border-radius: 5px;
      background: #fff;
      z-index: var(--z-9);
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0 0 3px rgba(0,0,0,0.1);
      user-select: none;
    }
  
    .spinner {
      width: 30px;
      height: 30px;
      border: 2px solid var(--color-blue-dark);
      border-top-color: rgba(0,0,0,0);
      border-radius: 50%;
      animation: spin 1s linear infinite;
      user-select: none;
    }
  
    @media screen and (max-width: 480px) {
      .loader-wrapper {
        padding-right: 0;
      }
    }
  </style>