<script>
  import { onMount, createEventDispatcher } from 'svelte';
  import flatpickr from "flatpickr";
  import Portuguese from "flatpickr/dist/l10n/pt.js"
  import { DMYtoTimestamp, DAY, toDMY, NOW } from '../helpers/Time';
  import PopupHelper from '../helpers/Popup';

  const dispatch = createEventDispatcher();

  let inputElement;
  export let value = toDMY(NOW);
  export let maxDays = false;
  export let startWithSingleDay = false;

  function fireEvent(dateString) {
    //ex. 10/04/2020 até 17/04/2020
    const bothValues = dateString.split(' até ');
    let valueStart = bothValues[0];
    let valueEnd;

    if (bothValues.length === 2) {
      valueEnd = bothValues[1];
    } else {
      // Caso tenha selecionado apenas um dia, usa o valueStart para o valueEnd
      valueEnd = valueStart;
    }

    // Caso nao tenha selecionado ambas as datas, nao dispara evento
    if (!valueEnd) {
      return;
    }

    if (maxDays !== false) {
      const startTimeStamp = DMYtoTimestamp(valueStart) * 1000;
      const endTimeStamp = DMYtoTimestamp(valueEnd) * 1000;
      const timeDiff = endTimeStamp - startTimeStamp;

      if (timeDiff / (DAY * 1000) > maxDays) {
        PopupHelper.alert(`Período maior que ${maxDays} dias.`, `Você tentou selecionar um período que ultrapassa o limite de ${maxDays} dias imposto pelo sistema. Tente novamente com um período menor.`);
        return;
      }
    }

    dispatch('change', {
      valueStart: DMYtoTimestamp(valueStart),
      valueEnd: DMYtoTimestamp(valueEnd),
    });
  }

  onMount(() => {
    const options = {
      dateFormat: 'd/m/Y',
      mode: 'range',
      locale: Portuguese.pt,
      
      onClose: (dates, dateStr, instance) => fireEvent(dateStr)
    };

    if (startWithSingleDay) {
      options.defaultDate = [value, value];
    }

    flatpickr(inputElement, options);
  });
</script>

<input type="text" bind:this={inputElement} class="field" value={value}>
