import { writable, get } from 'svelte/store';
import jwt_decode from 'jwt-decode';
import { contract } from './Contract';
import { api_keyed, URLS, api } from '../helpers/Request';

const LOCAL_KEY = 'mercurio-admin-login';
const TEMPORARY_ID_TOKEN_KEY = 'mercurio-temporary-id-token';

export function getLocalData() {
  return JSON.parse(localStorage.getItem(LOCAL_KEY) || JSON.stringify({ logged: false }));
}

function setLocalData(data) {
  return localStorage.setItem(LOCAL_KEY, JSON.stringify(data));
}

export function isLogged() {
  const user = getLocalData();
  return user.logged && user.idToken && jwt_decode(user.idToken).exp > Math.floor(+new Date() / 1000);
}

export function isLoggedAdmin(){
  const user = getLocalData();
  return user.logged && user.idToken && jwt_decode(user.idToken).exp > Math.floor(+new Date() / 1000) && jwt_decode(user.idToken)['custom:acesso'] == 'administradores';
}

export function canRelogin() {
  let user = getLocalData();
  return !!user.refreshToken;
}

function $auth() {
	const { subscribe, set } = writable(getLocalData());

	return {
		subscribe,
		login(email, idToken, refreshToken, accessToken) {
      const data = Object.assign({email, idToken, refreshToken, accessToken}, { logged: true });
      setLocalData(data);
      set(data);
    },
		logout() {
      setLocalData({ logged: false });
      set({ logged: false })
      contract.reset();
    },
    get() {
      return get(this);
    }
	};
}

export const auth = $auth();

export function initAuth() {
  let login = getLocalData();

  try {
    // If logged and token still valid, register login
    if (login.logged && jwt_decode(login.accessToken).exp > Math.floor(+new Date() / 1000)) {
      auth.login(login.email, login.idToken, login.refreshToken, login.accessToken);
    } else {
      if (login.refreshToken) {
        updateTokens();
        return;
      }

      auth.logout();
    }
  } catch(e) {
    auth.logout();
  }
}

export function logout() {
  auth.logout();
  // window.location.reload();
  window.location.href = '/login';
}

export async function updateTokens() {
  let login = getLocalData();

  if (!login.refreshToken) {
    return;
  }

  const result = await api.post(`${URLS.AUTENTICACAO}/funcionario/atualiza-tokens`, {
    json: login.refreshToken
  }).json();
  
  auth.login(login.email, result.IdToken, login.refreshToken, result.AccessToken);
  return;
}

export async function updateTokensAdministrador() {
  let login = getLocalData();

  if (!login.refreshToken) {
    return;
  }

  const result = await api.post(`${URLS.AUTENTICACAO}/administrador/atualiza-tokens`, {
    json: login.refreshToken
  }).json();
  
  auth.login(login.email, result.IdToken, login.refreshToken, result.AccessToken);
  return;
}

export function setTemporaryIdToken(idToken) {
  sessionStorage.setItem(TEMPORARY_ID_TOKEN_KEY, idToken);
}

export function hasTemporaryIdToken() {
  return !!sessionStorage.getItem(TEMPORARY_ID_TOKEN_KEY);
}

export function retornaGrupoUsuario(){
  const user = getLocalData();

  return jwt_decode(user.idToken)['custom:acesso'];
}