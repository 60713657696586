import { URLS, api_keyed_login } from "../helpers/Request";

export default {
  async createEmail(info_email) {
    try {
      const data = await api_keyed_login.post(`${URLS.USUARIO}/email`, {
        json: info_email
      }).json();
      return data;
    }catch(e){
      return false;
    }
  }
}