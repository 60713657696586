<script>
	import { fade } from 'svelte/transition';
	import formatHighlight from 'json-format-highlight';
	import { moveRight } from '../helpers/Animations';

	export let visible = false;
	export let json = '';
	export let jsonColors = {
		keyColor: '#4A7B70',
		numberColor: '#FD4545',
		stringColor: '#5a78ff',
		trueColor: '#E5981D',
		falseColor: '#E5981D',
		nullColor: '#E5981D'
	};

	function close() {
		visible = false;
	}
</script>

<style>
	.side_json {
		position: fixed;
		right: 0;
		top: 0;
		width: 450px;
		height: 100%;
		z-index: var(--z-9);
		background: #fff;
	}

	.side_json_overlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: var(--color-black-70);
		z-index: var(--z-8);
	}

	pre {
		display: block;
		margin: 20px;
		border-radius: 5px;
		background: #eee;
		padding: 10px;
		font-family: Consolas, Monaco, 'Courier New', Courier, monospace;
		font-size: 13px;
		line-height: 18px;
		max-height: calc(100vh - 40px);
		overflow: auto;
	}

	code {
		display: block;
	}
</style>

{#if visible}
	<div class="side_json" transition:moveRight>
		<pre>
			<code class="language-json">
				{@html formatHighlight(json, jsonColors)}
			</code>
		</pre>
	</div>
{/if}

{#if visible}
	<div class="side_json_overlay" transition:fade on:click={close} />
{/if}
