import { api_auth, URLS } from "../helpers/Request";

export default {
  async getCurrentPosition(contract) {
    try {
      const data = await api_auth.get(`${URLS.LOCALIZACAO}/${contract}`).json();
      return data;
    } catch (e) {
      console.error(e);
      return false;
    }
  },

  async getTravels(contract, from, to) {
    try {
      const data = await api_auth.get(`${URLS.VIAGENS}/por-contrato/com-coordenadas/${contract}?inicio=${from}&fim=${to}`).json();
      return data;
    } catch (e) {
      console.error(e);
      return false;
    }
  },

  async getTravelById(travelId) {
    try {
      return await api_auth.get(`${URLS.VIAGENS}/por-id/${travelId}`).json();
    } catch (e) {
      console.error(e);
      return false;
    }
  }

}
