export function getDisplayText(contract) {
	if (!contract.Plano) {
		return 'Carregando...';
	}

	const possuiGarantia = contract.Plano.PossuiGarantia;
	const garantiaAtiva = contract.Plano.GarantiaAtiva;
	const possuiRastreamento = contract.Plano.PossuiRastreamento;
	const rastreamentoAtivo = contract.Plano.RastreamentoAtivo;
	const planoSemDispositivo = contract.PlanoSemDipositivo;

	if (planoSemDispositivo === true && garantiaAtiva === true && possuiGarantia === true) {
		return 'Garantia Ativa';
	}

	if (possuiGarantia === true && garantiaAtiva === true && possuiRastreamento === true && rastreamentoAtivo === true) {
		return 'Garantia Ativa';
	}

	if (possuiGarantia === true && garantiaAtiva === false && possuiRastreamento === true && rastreamentoAtivo === true) {
		return 'Garantia Inativa';
	}

	if (
		possuiGarantia === true &&
		garantiaAtiva === false &&
		possuiRastreamento === true &&
		rastreamentoAtivo === false
	) {
		return 'Garantia Inativa';
	}

	if (possuiGarantia === true && garantiaAtiva === true && possuiRastreamento === true && rastreamentoAtivo === true) {
		return 'Garantia Ativa';
	}

	if (possuiGarantia === true && garantiaAtiva === true && possuiRastreamento === true && rastreamentoAtivo === false) {
		return 'Plano Inconsistente';
	}

	if (
		possuiGarantia === false &&
		garantiaAtiva === undefined &&
		possuiRastreamento === true &&
		rastreamentoAtivo === true
	) {
		return 'Rastreamento Ativo';
	}

	if (
		possuiGarantia === false &&
		garantiaAtiva === undefined &&
		possuiRastreamento === false &&
		rastreamentoAtivo === undefined
	) {
		return 'Plano Inconsistente';
	}

	// Caso não caia em nenhuma das anteriores, mostra padrao:
	return 'Garantia\nInativa';
}
