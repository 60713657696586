import { writable, get } from 'svelte/store';

function $pagination() {
	const { subscribe, set } = writable({});

	return {
    subscribe,
    set(data) {
      set(data);
    },
    get() {
      return get(this);
    }
	};
}

export const pagination = $pagination();