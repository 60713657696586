<script>
	import Menu from '../components/Menu.svelte';
	import Heading from '../components/Heading.svelte';
	import Footer from '../components/Footer.svelte';
	import Loader from '../components/Loader.svelte';
	import { auth, logout, isLoggedAdmin } from '../stores/Auth';
	import { contract } from '../stores/Contract';
	import UserService from '../services/User';

	let currentPassword = '';
	let newPassword = '';
	let newPasswordConfirm = '';
	let hasError = false;
	let isLoading = false;

	let isAdmin = isLoggedAdmin();

	const equalPasswords = () => newPassword === newPasswordConfirm ? hasError = false : hasError = true

	const handleForm = async () => {
		isLoading = true;

		if(isAdmin){
			const result = await UserService.updatePasswordAdministrador($auth.email, currentPassword, newPassword, $auth.accessToken);

			if(result){
				hasError = false;
				logout();
			}else{
				hasError = true;
			}

			isLoading = false;
		}else{
			const result = await UserService.updatePassword($auth.email, currentPassword, newPassword, $auth.accessToken);

			if(result){
				hasError = false;
				logout();
			}else{
				hasError = true;
			}

			isLoading = false;
		}
	}
</script>

<style>
	.action {
		margin-top: 10px;
	}

	.panel {
		max-width: 495px;
		margin: 0 auto;
	}

	.panel__inner {
		max-width: 304px;
		margin: 35px auto;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.label {
		color: var(--color-black-35);
		font-size: 1.2rem;
	}

	.name-title {
		font-size: 1.7rem;
		margin-top: 5px;
		margin-bottom: 45px;
	}

	form {
		margin: 14px 0 35px;
	}

	form .field {
		width: 100%;
		margin-bottom: 5px;
	}

	.action {
		display: flex;
		justify-content: flex-end;
	}

	p {
		line-height: 1.5rem;
	}
</style>

<Menu showNav={!!$contract && !!$contract.imei} />

<Loader visible={isLoading} />

<main>
	<div class="container">
		<Heading main="Gerenciar Conta" centered={true} />
		<div class="panel">
			<div class="panel__inner">
				<span class="label">Logado como</span>
				<h3 class="name-title">{$auth.email}</h3>
				<span class="label">Alterar Senha</span>
				<form on:submit|preventDefault={handleForm}>
					<input
						type="password"
						bind:value={currentPassword}
						class="field"
						placeholder="Senha atual" />
					<input
						type="password"
						bind:value={newPassword}
						class="field"
						on:keyup={equalPasswords}
						placeholder="Digite a nova senha" />
					<input
						type="password"
						bind:value={newPasswordConfirm}
						class="field"
						on:keyup={equalPasswords}
						placeholder="Digite a nova senha novamente" />
					{#if hasError}
						<div class="info info--error">
							<b>Atenção:</b>
							Senha atual é inválida ou novas senhas não batem. Verifique os
							campos e tente novamente
						</div>
					{:else}
						<div class="action">
							<button class="btn">Alterar Senha</button>
						</div>
					{/if}
				</form>

				<p>
					Caso você tenha esquecido ou perdido sua senha, por favor, entre em
					contato com a equipe de RH da Loovi pessoalmente, ou pelo email
					abaixo:
					<br />
					<br />
					<b>grupoti@loovi.com.br</b>
				</p>
			</div>
		</div>
	</div>
</main>

<Footer />
