<script>
  import { onMount, onDestroy } from 'svelte';
  import { Link } from 'svero';
  import randomColor from 'randomcolor';
  import MapTravel from './MapTravel.svelte';
  import DatePickerRange from './DatePickerRange.svelte';
  import Loader from './Loader.svelte';
  import { contract } from '../stores/Contract';
  import TravelsService from '../services/Travels';
  import Communication from '../services/Communication';
  import { NOW, DAY, toDMY, toYMD, toDMWithHours, toDMYWithHoursAndSeconds } from '../helpers/Time';
  import { vehicle } from '../stores/Vehicle';
  import { generateDistanceAndVelocity } from '../helpers/Travels';
  
  let from = NOW;
  let to = NOW;
  $: fromStart = Math.round(+new Date(`${toYMD(from)}T00:00:00`) / 1000);
  $: toEnd = Math.round(+new Date(`${toYMD(to)}T23:59:59`) / 1000);
  export let isTemporary = false;
  export let map;
  export let isMapReady = false;
  export let showEveryLatLong = false;
  export let travelId = false;
  let currentTravel = false;
  let isLoading = false;
  let isLoadingTravels = false;
  let isPageActive = true;
  let timeoutId;

  const lineColors = randomColor({
    count: 100,
    hue: 'blue'
  });

  let markers = [];

  async function loadLastCommunication() {
    if (isTemporary) {
      return;
    }
    
    if (!isPageActive) {
      return;
    }

    const result = await Communication.getLastCommunication($contract.imei || $contract.contractNumber);

    if (!result) {
      timeoutId = setTimeout(() => {
        loadLastCommunication();
      }, 1000 * 60 * 60);
      return;
    }

    $vehicle.lastCommunication = result;

    timeoutId = setTimeout(() => {
      loadLastCommunication();
    }, 30000);
  }

  function toggleRoute(travelId) {
    const vehicleRoute = $vehicle.routes.find(route => route.Id === travelId);
    const isActive = vehicleRoute.active;

    if (isActive) {
      vehicleRoute.active = false;
      clearMapMarker(vehicleRoute.Id);
    } else {
      vehicleRoute.active = true;
      showMapRoute(travelId);
    }

    $vehicle.routes = $vehicle.routes;
  }

  function clearMapMarker(id) {
    const marker = markers.filter(item => item.id === id);
    
    marker.forEach((mk, index) => {
      mk.item.setMap(null);
    });

    markers = markers.filter(item => item.id !== id);
  }

  function clearMapMarkers() {
    markers.forEach(marker => marker.item.setMap(null));
    markers = [];
    $vehicle.routes.filter(vehicle => vehicle.active = false);
    $vehicle.routes = $vehicle.routes;
  }

  function showMapRoute(travelId, singleRoute) {
    let vehicleRoute;

    if (singleRoute) {
      vehicleRoute = currentTravel;
    } else {
      vehicleRoute = $vehicle.routes.find(route => route.Id === travelId);
    }

    const coords = vehicleRoute.CoordenadasValidas.map(coord => {
      return {
        lat: +coord.lat,
        lng: +coord.long
      }
    });

    if (markers.length === 0) {
      map.setCenter({
        lat: coords[0].lat,
        lng: coords[0].lng
      });
    }

    const coordMap = new google.maps.Polyline({
      path: coords,
      geodesic: true,
      strokeColor: lineColors.sort(() => 0.5 - Math.random())[0], // '#5a78ff',
      strokeOpacity: 1.0,
      strokeWeight: 3
    });
    
    markers.push({id: vehicleRoute.Id, item: coordMap});

    markers.push({
      id: vehicleRoute.Id,
      item: new google.maps.Marker({
        position: coords[0],
        map,
        icon: {
          url: '/img/icon-map-a.svg',
        }
      })
    });

    markers.push({
      id: vehicleRoute.Id,
      item: new google.maps.Marker({
        position: coords[coords.length - 1],
        map,
        icon: {
          url: '/img/icon-map-b.svg',
        }
      })
    });

    if (showEveryLatLong) {
      const fullCoordsWithoutFirstLast = vehicleRoute.CoordenadasValidas.slice(1, -1);
      const infoWindow = new google.maps.InfoWindow({
        content: 'Carregando Detalhes Rota...'
      });

      let cordsWithoutFirstLast = coords.slice(1, -1);
      cordsWithoutFirstLast.forEach((coord, i) => {
        const marker = new google.maps.Marker({
          position: coord,
          map,
          icon: {
            url: '/img/icon-map-pin.svg',
          }
        });

        marker.addListener('click', () => {
          infoWindow.open(map, marker);
          infoWindow.setContent(JSON.stringify(fullCoordsWithoutFirstLast[i]));
        });

        markers.push({
          id: vehicleRoute.Id,
          item: marker
        });
      });
    }

    coordMap.setMap(map);
  }

  async function loadTravel() {
    isLoading = true;
    const result = await TravelsService.getTravelById(travelId);

    if (result.error) {
      isLoading = false;
      PopupHelper.alert('Erro de Servidor', result.message);
      return;
    }

    currentTravel = result;

    const routeData = generateDistanceAndVelocity(result.CoordenadasValidas);

    currentTravel.totalDistance = routeData.distance;
    currentTravel.maximumVelocity = routeData.maximumVelocity;
    currentTravel.mediumVelocity = routeData.mediumVelocity;

    showMapRoute(null, true);
    isLoading = false;
  }

  async function loadTravels() {
    isLoadingTravels = true;
    const result = await TravelsService.getTravels($contract.contractNumber, fromStart, toEnd);

    if (result.error) {
      isLoadingTravels = false;
      PopupHelper.alert('Erro de Servidor', result.message);
      return;
    }

    result.forEach(travel => {
      const routeData = generateDistanceAndVelocity(travel.CoordenadasValidas);

      travel.totalDistance = routeData.distance;
      travel.maximumVelocity = routeData.maximumVelocity;
      travel.mediumVelocity = routeData.mediumVelocity;
    });

    $vehicle.routes = result;
    $vehicle = $vehicle;
    isLoadingTravels = false;
  }

  function updateFrom(date) {
    from = date;
    // setTimeout(() => {
    //   loadTravels();
    // }, 100);
  }

  function updateTo(date) {
    to = date;
    // setTimeout(() => {
    //   loadTravels();
    // }, 100);
  }

  function updateFromTo(dates) {
    updateFrom(dates.valueStart);
    updateTo(dates.valueEnd);

    setTimeout(() => {
      loadTravels();
    }, 100);
  }

  function showDetailsSingleRoute() {
    clearMapMarker(currentTravel.Id);
    setTimeout(() => {
      showMapRoute(null, true);
    }, 50);
  }

  onMount(() => {
    // Carrega viagens apenas se não for mapa temporário
    if (!isTemporary) {
      if (travelId) {
        loadTravel();
      } else {
        loadTravels();
      }
    } else {
      isLoading = false;
    }
    window.CONTRACT = $contract;
    loadLastCommunication();
  });

  onDestroy(() => {
    isPageActive = false;
    clearTimeout(timeoutId);
  });
</script>

<Loader visible={isLoading} />

<div class="map-sidebar" class:map-sidebar--temp={isTemporary}>
  <div class="map-sidebar__mobile__trigger"></div>
  <div class="map-sidebar__body">
    <div class="plate">
      <div class="plate__label">Placa</div>
      {($contract.plate || '').toUpperCase() || 'N/A'}
    </div>
    <div class="stats">
      <div class="stats__row">
        <div class="stats__item">
          <div class="stats__item__label">VELOCIDADE</div>
          {#if $vehicle.position.error}
            <div class="stats__item__value">0 km/h</div>
          {:else}
            <div class="stats__item__value">{Math.round($vehicle.position.vel)} km/h</div>
          {/if}
        </div>
      </div>
      <hr>
      <div class="stats__row">
        <div class="stats__item stats__item--small">
          <div class="stats__item__label">IMEI</div>
          <div class="stats__item__value">{$contract.imei}</div>
        </div>
      </div>
      {#if !isTemporary}
        <div class="stats__row">
          <div class="stats__item stats__item--small">
            <div class="stats__item__label">ICCID</div>
            {#if $contract.telefonia && $contract.telefonia.Iccid}
              <div class="stats__item__value">{$contract.telefonia.Iccid}</div>
            {:else}
              <div class="stats__item__value">Não disponível</div>
            {/if}
          </div>
        </div>
      {/if}
      <div class="stats__row">
        <div class="stats__item stats__item--small">
          <div class="stats__item__label">ÚLTIMA LOCALIZAÇÃO</div>
          {#if $vehicle.position.error}
            <div class="stats__item__value">--</div>
          {:else}
            <div class="stats__item__value">{toDMYWithHoursAndSeconds($vehicle.position.ts)}</div>
          {/if}
        </div>
      </div>
      {#if !isTemporary}
        <div class="stats__row">
          <div class="stats__item stats__item--small">
            <div class="stats__item__label">ÚLTIMA COMUNICAÇÃO</div>
            {#if !$vehicle.lastCommunication}
              <div class="stats__item__value">--</div>
            {:else}
              <div class="stats__item__value">{toDMYWithHoursAndSeconds($vehicle.lastCommunication)}</div>
            {/if}
          </div>
        </div>
      {/if}
    </div>
  </div>
  {#if currentTravel}
    <div class="paths">
      <div class="paths__filter">
        <label class="paths__filter__title">
          <input type="checkbox" bind:checked={showEveryLatLong} on:change={showDetailsSingleRoute}>
          Mostrar Detalhes
        </label>
        <br>
        <br>
        <MapTravel totalDistance={currentTravel.totalDistance} maximumVelocity={currentTravel.maximumVelocity} mediumVelocity={currentTravel.mediumVelocity} dateDiff={currentTravel.DataHoraFim - currentTravel.DataHoraInicio} dateStart={toDMWithHours(currentTravel.DataHoraInicio)} dateEnd={toDMWithHours(currentTravel.DataHoraFim)} routeA={currentTravel.EnderecoInicio} routeB={currentTravel.EnderecoFim} id={currentTravel.Id} isActive={true} readonly={true} />
        <Link class="btn btn--outline btn--full" href=/{window.location.pathname.split('/').slice(1, 3).join('/')}>Buscar outras rotas</Link>
      </div>
    </div>
  {/if}
  {#if isMapReady && !isTemporary && !isLoading && !currentTravel}
    <div class="paths">
      <div class="paths__filter">
        <div class="paths__filter__title">FILTRAR ROTAS</div>
        <div class="paths__filter__inputs">
          <DatePickerRange startWithSingleDay={true} maxDays={7} on:change={(e) => updateFromTo(e.detail)} />
        </div>
        {#if markers.length === 0}
        <label class="paths__filter__title">
          <input type="checkbox" bind:checked={showEveryLatLong}>
          Mostrar Detalhes
        </label>
        {/if}
        {#if markers.length > 0}
        <label class="paths__filter__disclaimer">
          Para expandir ou remover os detalhes é necessário limpar todas as rotas primeiro.
        </label>
        {/if}
      </div>
      {#if markers.length > 0}
        <button class="btn btn--error" on:click={clearMapMarkers}>Limpar Todas</button>
      {/if}
      {#if $vehicle.routes.length > 0}
        {#if isLoadingTravels}
          <Loader visible={true} local={true} />
        {:else}
          {#each $vehicle.routes as vroute}
            <MapTravel totalDistance={vroute.totalDistance} maximumVelocity={vroute.maximumVelocity} mediumVelocity={vroute.mediumVelocity} dateDiff={vroute.DataHoraFim - vroute.DataHoraInicio} dateStart={toDMWithHours(vroute.DataHoraInicio)} dateEnd={toDMWithHours(vroute.DataHoraFim)} routeA={vroute.EnderecoInicio} routeB={vroute.EnderecoFim} id={vroute.Id} isActive={vroute.active} on:active={() => toggleRoute(vroute.Id) } />
          {/each}
        {/if}
      {:else}
        {#if isLoadingTravels}
          <Loader visible={true} local={true} />
        {:else}
          <div class="map-sidebar__empty">Nenhuma rota encontrada nesta data.</div>
        {/if}
      {/if}
    </div>
  {/if}
</div>

<style>
  :global(.btn--full) {
    width: 100%;
  }

  .map-sidebar__empty {
    margin: 20px;
    padding: 30px;
    text-align: center;
    font-size: 15px;
    color: #555;
    background: #eee;
    border-radius: 5px;
  }

  .map-sidebar {
    position: absolute;
    top: 149px;
    right: 0;
    z-index: 2;
    width: 290px;
    height: calc(100% - 149px);
    overflow: auto;
    background: #fff;
    box-shadow: -2px 0 4px var(--color-black-10);
  }

  @media screen and (max-width: 600px) {
    .map-sidebar {
      width: 100%;
      top: auto !important;
      bottom: 0;
      height: auto;
      overflow: visible;
      transform: translate3d(0, 100%, 0);
    }

    .map-sidebar--open {
      transform: translate3d(0, 0, 0);
    }

    .map-sidebar__mobile__trigger {
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      height: 20px;
      background: var(--color-green-dark);
      box-shadow: 0 2px 6px rgba(0,0,0,0.2);
    }

    /* .map-sidebar__mobile__trigger {
      position: relative;
      top: 0;
      left: 50%;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background: var(--color-green-dark);
      border-radius: 50%;
      box-shadow: 0 2px 6px rgba(0,0,0,0.2);
    } */

    /* .map-sidebar__mobile__trigger:before {
      content: '';
      width: 20px;
      height: 2px;
      background: #fff;
      margin-bottom: 4px;
    }

    .map-sidebar__mobile__trigger:after {
      content: '';
      width: 20px;
      height: 2px;
      background: #fff;
    } */
  }

  .map-sidebar--temp {
    top: 114px;
    height: auto;
  }

  .map-sidebar__body {
    padding: 15px 12px;
  }

  .empty-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
    padding: 15px 10px;
    border-radius: 5px;
    background: var(--color-blue-dark-10);
    text-align: center;
  }

  .empty-message p {
    color: var(--color-blue-dark);
  }

  .empty-message img {
    margin-bottom: 10px;
  }

  .plate {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 66px;
    position: relative;
    border: 1px solid #848484;
    background: linear-gradient(90deg, #F2F2F2, #D5D5D5 25%, #EDEDED 75%, #C3C3C3);
    font-size: 2.2rem;
    font-weight: bold;
    border-radius: 5px;
    margin-bottom: 10px;
  }

  .plate:before {
    content: '';
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 3px;
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    border: 1px solid rgba(132, 132, 132, 0.25);
  }

  .plate:after {
    content: '';
    width: 140px;
    height: 4px;
    background: url(/img/plate-clamps.svg) no-repeat;
    background-size: 100% auto;
    position: absolute;
    top: 10px;
    left: 50%;
    margin-left: -70px;
  }

  .plate__label {
    font-size: 1.2rem;
    color: #747474;
    text-align: center;
    margin-bottom: 4px;
    text-transform: uppercase;
  }

  .stats__row {
    display: flex;
    margin-bottom: 10px;
  }

  .stats__item {
    flex: 1;
    width: 100%;
    height: 66px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-right: 10px;
    border: 1px solid var(--color-blue-dark-40);
    border-radius: 5px;
  }

  .stats__item.stats__item--small {
    padding: 10px;
    height: 48px;
    align-items: flex-start;
  }

  .stats__item:last-child {
    margin-right: 0;
  }

  .stats__item__label {
    font-size: 1.1rem;
    font-weight: 300;
    color: var(--color-blue-dark);
    margin-bottom: 4px;
  }

  .stats__item__value {
    font-size: 1.4rem;
    font-weight: bold;
    color: var(--color-blue-dark);
  }

  hr {
    width: 100%;
    height: 2px;
    display: block;
    border: none;
    margin: 15px 0;
    background: var(--color-black-10);
  }

  .paths {
    padding: 14px 12px;
    background: var(--color-black-05);
  }

  .paths__filter {
    background: var(--color-black-05);
    margin: -14px -12px 10px;
    padding: 10px;
  }

  .paths__filter__title {
    font-size: 1.1rem;
    font-weight: bold;
    text-transform: uppercase;
    color: var(--color-black-35);
  }

  .paths__filter__inputs {
    display: flex;
  }

  .paths__filter__inputs :global(.field) {
    flex: 1;
    min-width: 0;
    margin: 5px 0;
    color: var(--color-blue);
    background: url(/img/icon-datepicker.svg) right 10px center no-repeat #fff;
    padding-right: 40px;
  }

  .btn.btn--error {
    width: 100%;
    margin-bottom: 10px;
  }

  .paths__filter__disclaimer {
    display: block;
    font-size: 12px;
    font-weight: bold;
    color: #555;
    padding: 8px 10px;
    border-radius: 3px;
    background: #fff;
  }
</style>