<script>
  import { onMount, createEventDispatcher, getContext, tick } from 'svelte';
  import { pagination } from '../stores/Pagination';

  const dispatch = createEventDispatcher();

  const apiInitial = getContext('pagination_initial_load');
  const apiChunk = getContext('pagination_chunk_load');

  export const amountPerPage = 20;
  let items = [];
  let currentPage = 1;
  let hasNextPage = false;
  let nextPageToken = '';
  let isLoading = false;

  $: pages = Math.ceil(items.length / amountPerPage) || 1;
  $: chunks = (() => {
    const itemsPerPage = [];

    for (let i = 0; i < pages; i++) {
      itemsPerPage[i] = items.slice(i * amountPerPage, i * amountPerPage + amountPerPage);
    }

    return itemsPerPage;
  })();

  function loading(status) {
    isLoading = status;
    dispatch('loading', status);
  }

  async function firstLoad() {
    loading(true);
    const result = await apiInitial(amountPerPage);

    if (!result) {
      loading(false);
      return;
    }

    items = result.Itens;
    hasNextPage = result.PossuiProximaPagina;
    nextPageToken = result.ProximaPagina;
    
    await tick();
    
    
    dispatch('change', chunks[currentPage - 1]);
    loading(false);
  }

  async function chunkLoad() {
    loading(true);
    const result = await apiChunk(amountPerPage, nextPageToken);

    if (!result) {
      loading(false);
      return;
    }

    items = [...items, ...result.Itens];
    hasNextPage = result.PossuiProximaPagina;
    nextPageToken = result.ProximaPagina || '';

    await tick();

    dispatch('change', chunks[currentPage - 1]);
    loading(false);
  }

  async function gotoPage(i) {
    currentPage = i + 1;

    await tick();

    dispatch('change', chunks[i]);
  }

  async function prev() {
    if (currentPage === 1) {
      return;
    }

    currentPage -= 1;

    await tick();

    dispatch('change', chunks[currentPage - 1]);
  }

  async function next() {
    if (currentPage === pages) {
      currentPage += 1;
      await chunkLoad();
      return;
    }

    currentPage += 1;

    await tick();

    dispatch('change', chunks[currentPage - 1]);
  }

  onMount(() => {
    firstLoad();
  });
</script>
<nav class="pagination">
  <ul class:disabled={isLoading}>
    <li class:disabled={currentPage === 1} on:click={prev}>Anterior</li>
    {#each chunks as _, i}
    {#if i + 3 > currentPage || i - 3 < currentPage}
    <li class:active={i + 1 === currentPage} on:click={() => gotoPage(i)}>{i + 1}</li>
    {/if}
    {/each}
    <li class:disabled={currentPage === pages && !hasNextPage} on:click={next}>Próxima</li>
  </ul>
  <span>Mostrando página {currentPage} de {isLoading ? '..' : pages}.</span>
</nav>

<style>
  .pagination {
    border-top: 1px solid #ccc;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    /* margin-top: 10px; */
    padding: 10px;
  }

  span {
    color: var(--color-black-70);
    font-size: 12px;
    margin-top: 10px;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    user-select: none;
  }

  ul.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  ul li {
    cursor: pointer;
    padding: 8px 10px;
    border: 1px solid #aaa;
    margin-left: -1px;
  }

  ul li:first-child {
    border-radius: 4px 0 0 4px;
  }

  ul li:last-child {
    border-radius: 0 4px 4px 0;
  }

  ul li.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  ul li.active {
    color: #fff;
    background-color: var(--color-blue);
    border-color: var(--color-blue);
  }
</style>