<script>
  import { onMount } from 'svelte';
  import { fade } from 'svelte/transition';
  import { scale } from '../helpers/Animations';
  import { contract } from '../stores/Contract';
  import { getDisplayText } from '../helpers/Contract';

  export let show = false;
  let title = '';
  let vehicle = '--';

  function close() {
    show = true;
    show = false;
  }

  onMount(() => {
    contract.subscribe(() => {
      if ($contract.raw) {
        title = getDisplayText($contract.raw);

        if ($contract.raw.Veiculo) {
          if (!$contract.raw.Veiculo.Placa) {
            vehicle = 'N/A';
            return;
          }

          vehicle = `${$contract.raw.Veiculo.Placa}`;
        }
      }
    });
  });
</script>

{#if show}
<div class="garantia_ativa__overflow" on:click={close} transition:fade></div>

<div class="garantia_ativa__popup" transition:scale>
  <div class="garantia_ativa__popup__header">
    <button class="garantia_ativa__popup__close" on:click={close}>
      <img src="/img/icon-garantia-close.svg" alt="">
    </button>
    {#if title.includes('Ativ')}
      <img src="/img/icon-garantia-check.svg" alt="">
    {:else}
      <img src="/img/icon-garantia-error.svg" alt="">
    {/if}
    <h2 class="garantia_ativa__popup__header__title">{title}</h2>
  </div>
  {#if $contract.raw}
    <div class="garantia_ativa__popup__body">
      <div class="garantia_ativa__popup__body__car">{vehicle}</div>
      {#if $contract.raw.Dispositivo.PossuiPrimeiraAlimentacao || $contract.raw.Pagamentos.PossuiInadimplencia || $contract.raw.StatusFotos === 'todas-aprovadas'}
        <p class="garantia_ativa__popup__body__resume">Fique tranquilo todas as etapas para a validação da sua garantia estão 100% completas.</p>
      {:else}
        <p class="garantia_ativa__popup__body__resume">Por favor, resolva as pendências listadas abaixo.</p>
      {/if}
      <div class="garantia_ativa__popup__body__items">
        {#if $contract.raw.Dispositivo.PossuiPrimeiraAlimentacao || $contract.raw.Dispositivo.PossuiDispositivo}
          <div class="garantia_ativa__popup__body__items__item">
            {#if $contract.raw.Dispositivo.PossuiPrimeiraAlimentacao}
              <img class="garantia_ativa__popup__body__items__item__icon" src="/img/icon-garantia-dispositivo-on.svg" alt="">
              <div class="garantia_ativa__popup__body__items__item__text">
                <h2>Dispositivo</h2>
                <h3>Dispositivo plugado.</h3>
              </div>
              <img class="garantia_ativa__popup__body__items__item__status" src="/img/icon-garantia-check.svg" alt="">
            {:else if $contract.raw.Dispositivo.PossuiDispositivo}
              <img class="garantia_ativa__popup__body__items__item__icon" src="/img/icon-garantia-dispositivo-off.svg" alt="">
              <div class="garantia_ativa__popup__body__items__item__text">
                <h2 class="text--off">Dispositivo</h2>
                <h3>Plugue seu dispositivo.</h3>
              </div>
              <img class="garantia_ativa__popup__body__items__item__status" src="/img/icon-garantia-error.svg" alt="">
            {/if}
          </div>
        {/if}
        <div class="garantia_ativa__popup__body__items__item">
          {#if !$contract.raw.Pagamentos.PossuiInadimplencia}
            <img class="garantia_ativa__popup__body__items__item__icon" src="/img/icon-garantia-pagamento-on.svg" alt="">
            <div class="garantia_ativa__popup__body__items__item__text">
              <h2>Pagamento</h2>
              <h3>Pagamentos em dia.</h3>
            </div>
            <img class="garantia_ativa__popup__body__items__item__status" src="/img/icon-garantia-check.svg" alt="">
          {:else}
            <img class="garantia_ativa__popup__body__items__item__icon" src="/img/icon-garantia-pagamento-off.svg" alt="">
            <div class="garantia_ativa__popup__body__items__item__text">
              <h2 class="text--off">Pagamento</h2>
              <h3>Regularize seus pagamentos.</h3>
            </div>
            <img class="garantia_ativa__popup__body__items__item__status" src="/img/icon-garantia-error.svg" alt="">
          {/if}
        </div>
        <div class="garantia_ativa__popup__body__items__item">
          {#if $contract.raw.StatusFotos === 'todas-aprovadas'}
            <img class="garantia_ativa__popup__body__items__item__icon" src="/img/icon-garantia-fotos-on.svg" alt="">
            <div class="garantia_ativa__popup__body__items__item__text">
              <h2>Fotos e documentação</h2>
              <h3>Suas fotos foram aprovadas.</h3>
            </div>
            <img class="garantia_ativa__popup__body__items__item__status" src="/img/icon-garantia-check.svg" alt="">
          {:else}
            <img class="garantia_ativa__popup__body__items__item__icon" src="/img/icon-garantia-fotos-off.svg" alt="">
            <div class="garantia_ativa__popup__body__items__item__text">
              <h2 class="text--off">Fotos e documentação</h2>
              <h3>Problemas com suas fotos.</h3>
            </div>
            <img class="garantia_ativa__popup__body__items__item__status" src="/img/icon-garantia-error.svg" alt="">
          {/if}
        </div>
      </div>
    </div>
  {/if}
</div>
{/if}

<style>
  .garantia_ativa__overflow {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: var(--z-6);
    background: rgba(0,0,0,0.5);
  }

  .garantia_ativa__popup {
    width: 316px;
    background: #EFF2F7;
    border-radius: 20px;
    z-index: var(--z-7);
    position: absolute;
    left: 50%;
    top: 10%;
    margin-left: -168px;
    overflow: hidden;
  }

  .garantia_ativa__popup__close {
    width: 40px;
    height: 40px;
    position: absolute;
    right: 0;
    top: 0;
    border: none;
    background: transparent;
    margin-right: 5px;
    margin-top: 5px;
  }

  .garantia_ativa__popup__header {
    background: #fff;
    padding: 30px 10px 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .garantia_ativa__popup__header__title {
    font-size: 20px;
    font-weight: bold;
    color: var(--color-blue);
    margin-top: 15px;
  }

  .garantia_ativa__popup__body {
    padding: 0 30px 30px;
  }

  .garantia_ativa__popup__body__car {
    background: #fff;
    border: 2px solid #9FA5B3;
    border-radius: 5px;
    width: 256px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 38px;
    margin-top: -19px;
    font-size: 14px;
    color: #9FA5B3;
  }

  .garantia_ativa__popup__body__resume {
    font-size: 10px;
    line-height: 15px;
    color: #9FA5B3;
    margin: 20px 0;
  }

  .garantia_ativa__popup__body__items {
  }

  .garantia_ativa__popup__body__items__item {
    position: relative;
    margin-top: 20px;
    display: flex;
    align-items: center;
    background: #fff;
    border: 1px solid #E2E4E8;
    padding: 18px;
    border-radius: 15px;
  }

  .garantia_ativa__popup__body__items__item__status {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 16px;
    height: 16px;
  }

  .garantia_ativa__popup__body__items__item__text {
    flex: 1;
    margin-left: 15px;
  }

  .garantia_ativa__popup__body__items__item__text h2 {
    font-size: 12px;
    font-weight: bold;
    color: #47DCA3;
    margin-bottom: 5px;
  }

  .garantia_ativa__popup__body__items__item__text h3 {
    font-size: 10px;
    color: #9FA5B3;
  }

  .text--off {
    color: #FF5630 !important;
  }
</style>