export const NOW = Math.floor(+new Date() / 1000);

export const SECOND = 1;
export const MINUTE = SECOND * 60;
export const HOUR = MINUTE * 60;
export const DAY = HOUR * 24;
export const WEEK = DAY * 7;
export const MONTH = WEEK * 4;
export const YEAR = MONTH * 12;


export function toDMYWithHoursAndSeconds(timestamp) {
  return (new Date(timestamp * 1000)).toLocaleDateString('pt-BR',
  { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' });
}

export function toDMYWithHours(timestamp) {
  return (new Date(timestamp * 1000)).toLocaleDateString('pt-BR',
  { year: 'numeric', month: 'numeric', day: '2-digit', hour: '2-digit', minute: '2-digit' });
}

export function toDMWithHours(timestamp) {
  return (new Date(timestamp * 1000)).toLocaleDateString('pt-BR',
  { month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' });
}

export function toDMY(timestamp) {
  return (new Date(timestamp * 1000)).toLocaleDateString('pt-BR');
}

export function toYMD(timestamp) {
  return (new Date(timestamp * 1000)).toLocaleDateString('pt-BR').split('/').reverse().join('-');
}

export function DMYtoTimestamp(dmy) {
  return Math.floor(+new Date(dmy.split('/').reverse().join('-') + ' 03:00:00') / 1000);
}

export function toHHMM(ms) {
  const date = new Date(ms).toISOString().substr(11,5).split(':');

  return {
    hours: date[0],
    minutes: date[1],
  }
}

export function toWrittenHoursMinutes(ms) {
  const date = toHHMM(ms);

  if (+date.hours === 0) {
    return `${date.minutes} minutos`;
  }

  if (+date.hours === 1) {
    return `${date.hours} hora e ${date.minutes} minutos`;
  }

  return `${date.hours} horas e ${date.minutes} minutos`;
}

export function wait(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}
