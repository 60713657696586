<script>
  import { onMount, setContext, tick } from 'svelte';
  import { navigateTo } from 'svero';
  import Menu from '../components/Menu.svelte';
  import Heading from '../components/Heading.svelte';
  import Footer from '../components/Footer.svelte';
  import Loader from '../components/Loader.svelte';
  import SideJson from '../components/SideJson.svelte';
  import { contract } from '../stores/Contract';
  import { pagination } from '../stores/Pagination';
  import { NOW, DAY, toYMD, toDMYWithHoursAndSeconds, toDMY } from '../helpers/Time';
  import EventsService from '../services/Events';
  import ContractService from '../services/Contract';
  import DatePickerRange from '../components/DatePickerRange.svelte';
  import Pagination from '../components/Pagination.svelte';

  let from = NOW;
  let to = NOW;
  $: fromStart = Math.round(+new Date(`${toYMD(from)}T00:00:00`) / 1000);
  $: toEnd = Math.round(+new Date(`${toYMD(to)}T23:59:59`) / 1000);
  let isLoading = true;
  let isJsonOpen = false;
  let json = '';
  let selectedType = '';
  let isErrorPopupOpen = false;
  let events = [];
  let eventTypes = [];
  let startRequests = false;
  let forceReload = false;

  async function paginationInitialLoad(amount) {
    return await EventsService.getEventsByType(amount);
  }

  async function paginationChunkLoad(amount, nextPageToken) {
    return await EventsService.getEventsByTypeNextPage(amount, nextPageToken);
  }

  function openJsonView(data) {
    isJsonOpen = false;
    isJsonOpen = true;
    json = JSON.stringify(data, null, 2);
  }

  async function getEventTypes() {
    const resultEventTypes = await EventsService.getEventTypes();

    if (!resultEventTypes) {
      PopupHelper.alert('Erro de Servidor', `Erro de servidor ao buscar tipos de eventos.`);
      return;
    }

    eventTypes = resultEventTypes;
    selectedType = eventTypes[0];
  }

  async function assignContract(contractNumber) {
    isLoading = true;
    const contractData = await ContractService.getContract(contractNumber);
    
    if (!contractData) {
      isLoading = false;
      return;
    }

    const phoneData = await ContractService.getPhoneData((contractData.Dispositivo || {}).Imei);

    $contract = {
      clientName: contractData.Nome || 'Usuário',
			contractNumber: contractNumber,
			imei: contractData.Dispositivo.Imei,
			plate: contractData.Veiculo.Placa,
			vehicle: contractData.Veiculo.Modelo,
			picturesStatus: contractData.StatusFotos,
      telefonia: phoneData || {},
      raw: contractData,
    };

    isLoading = false;

    navigateTo('/busca-contrato');
  }

  async function updateFromTo(e) {
    from = e.detail.valueStart;
    to = e.detail.valueEnd;

    await tick();

    $pagination.from = fromStart;
    $pagination.to = toEnd;
  }

  async function search() {
    $pagination.type = selectedType;

    forceReload = true;

    await tick();

    setTimeout(() => {
      forceReload = false;
      startRequests = true;
    }, 1)

  }

  async function handlePageChange(e) {
    await tick();
    events = e.detail;
  }

  setContext('pagination_initial_load', paginationInitialLoad);
  setContext('pagination_chunk_load', paginationChunkLoad);

  onMount(async () => {
    await getEventTypes();
    isLoading = false;
    
    $pagination.from = fromStart;
    $pagination.to = toEnd;
  });
</script>
  
  <Menu showNav={!!$contract && !!$contract.imei} /> 

  <SideJson visible={isJsonOpen} json={json} />

  <main>
    <div class="container">
      <Heading main="Últimos Eventos" />

      <div class="panel">
        <div class="panel__head">
          <h2 class="panel__head__title">
            Ações Disponíveis
          </h2>
        </div>
        <div class="panel__row">
          <div class="panel__forms panel__forms--full">
            <div class="panel__forms__col">
              <label class="panel__forms__label">Período:</label>
              <div class="panel__forms__value">
                <DatePickerRange maxDays={7} startWithSingleDay={true} on:change={updateFromTo} />
              </div>
            </div>
            <div class="panel__forms__col">
              <label class="panel__forms__label">Tipo de Evento:</label>
              <div class="panel__forms__value">
                <select class="field" bind:value={selectedType}>
                  {#each eventTypes as type}
                    <option value={type}>{type}</option>
                  {/each}
                </select>
              </div>
            </div>
            <div class="panel__forms__col">
              <label class="panel__forms__label">&nbsp;</label>
              <div class="panel__forms__value">
                <button class="btn" on:click={search}>Filtrar</button>
              </div>
            </div>
          </div>
        </div>
        <div class="panel__row panel__row--vertical">
          <table class="grid">
            <thead>
              <tr>
                <th width="20%">Tipo</th>
                <th width="20%">Data e Hora</th>
                <th width="20%">Contrato</th>
                <th width="20%">IMEI</th>
                <th width="5%">Mapa</th>
                <th width="5%">JSON</th>
                <th width="10%">Posicionar</th>
              </tr>
            </thead>
          </table>
          <div class="grid__scroller">
            {#if isLoading}
              <Loader local={true} visible={isLoading} />
            {:else}
              {#if events.length > 0}
                <table class="grid">
                  <tbody>
                    {#each events as event}
                    <tr>
                      <td width="20%" class:grid__status--success={true} class:grid__status--error={event.Tipo.includes('erro-') || event.Tipo.includes('erros-')} class:grid__status--info={event.Tipo.includes('comando-')}>{event.Tipo}</td>
                      <td width="20%">{toDMYWithHoursAndSeconds(event.DataHora)}</td>
                      <td width="20%">{event.Contrato || '--'}</td>
                      <td width="20%">{event.Imei || '--'}</td>
                      <td width="5%">
                        {#if !event.Latitude || !event.Longitude}
                          <span>N/A</span>
                        {:else}
                          <a class="grid__btn" href="https://www.google.com.br/maps/search/?api=1&query={event.Latitude},{event.Longitude}" target="_blank">
                            <img src="/img/icon-map.svg" alt="">
                          </a>
                        {/if}
                      </td>
                      <td width="5%">
                        <button class="grid__btn" on:click={() => openJsonView(event)}>
                          <img src="/img/icon-code.svg" alt="">
                        </button>
                      </td>
                      <td width="10%">
                        <button class="grid__btn" on:click={() => assignContract(event.Contrato)}>
                          <img src="/img/icon-arrow-right.svg" alt="">
                        </button>
                      </td>
                    </tr>
                    {/each}
                  </tbody>
                </table>
              {:else}
                <div class="grid__empty">Nenhum evento encontrado neste período.</div>
              {/if}
            {/if}
          </div>
          {#if startRequests && !forceReload}
            <Pagination on:change={handlePageChange} on:loading={(e) => isLoading = e.detail} />
          {/if}
        </div>
      </div>

    </div>
  </main>
  
  <Footer />
  
  <style>
    .panel__forms__col {
      min-width: 200px;
    }

    .panel__forms__value {
      min-width: 200px;
    }
  
    .panel__forms__value :global(input) {
      width: 100%;
    }
  </style>