import { api_auth, URLS } from "../helpers/Request";
import { pagination } from "../stores/Pagination";

export default {
  async getEventTypes() {
    try {
      return await api_auth.get(`${URLS.EVENTOS}/tipos`).json();
    } catch (e) {
      return false;
    }
  },

  async getEventsByType(amount) {
    const data = pagination.get();
    const type = data.type;
    const from = data.from;
    const to = data.to;

    try {
      const data = await api_auth.get(`${URLS.EVENTOS}/pesquisa-por-tipo/${type}/${amount}?inicio=${from}&fim=${to}`).json();

      if (data === false) {
        return data;
      }

      data.Itens = data.Itens.reverse();
      return data;
    } catch (e) {
      console.error(e);
      return false;
    }
  },

  async getEventsByTypeNextPage(amount, nextPageToken) {
    const data = pagination.get();
    const type = data.type;
    const from = data.from;
    const to = data.to;
    
    try {
      const data = await api_auth.get(`${URLS.EVENTOS}/pesquisa-por-tipo/${type}/${amount}/${nextPageToken}?inicio=${from}&fim=${to}`).json();

      if (data === false) {
        return data;
      }

      data.Itens = data.Itens.reverse();
      return data;
    } catch (e) {
      console.error(e);
      return false;
    }
  },

  async getEvents(contract, amount) {
    const data = pagination.get();
    const from = data.from;
    const to = data.to;

    try {
      const data = await api_auth.get(`${URLS.EVENTOS}/pesquisa-por-contrato/${contract}/${amount}?inicio=${from}&fim=${to}`).json();

      if (data === false) {
        return data;
      }

      data.Itens = data.Itens;
      return data;
    } catch (e) {
      console.error(e);
      return false;
    }
  },

  async getEventsNextPage(contract, amount, nextPageToken) {
    const data = pagination.get();
    const from = data.from;
    const to = data.to;

    try {
      const data = await api_auth.get(`${URLS.EVENTOS}/pesquisa-por-contrato/${contract}/${amount}/${nextPageToken}?inicio=${from}&fim=${to}`).json();

      if (data === false) {
        return data;
      }

      data.Itens = data.Itens;
      return data;
    } catch (e) {
      console.error(e);
      return false;
    }
  },

  async getEventsByImei(imei, amount) {
    const data = pagination.get();
    const from = data.from;
    const to = data.to;

    try {
      const data = await api_auth.get(`${URLS.EVENTOS}/pesquisa-por-imei/${imei}/${amount}?inicio=${from}&fim=${to}`).json();

      if (data === false) {
        return data;
      }

      data.Itens = data.Itens.reverse();
      return data;
    } catch (e) {
      console.error(e);
      return false;
    }
  },

  async getEventsByImeiNextPage(imei, amount, nextPageToken) {
    const data = pagination.get();
    const from = data.from;
    const to = data.to;

    try {
      const data = await api_auth.get(`${URLS.EVENTOS}/pesquisa-por-imei/${imei}/${amount}/${nextPageToken}?inicio=${from}&fim=${to}`).json();

      if (data === false) {
        return data;
      }

      data.Itens = data.Itens.reverse();
      return data;
    } catch (e) {
      console.error(e);
      return false;
    }
  },

  async getCommands() {
    try {
      const data = await api_auth.get(`${URLS.COMANDOS}/sugeridos`).json();

      if (data === false) {
        return data;
      }

      if (data.TipoErro) {
        return false;
      } else {
        return data;
      }
    } catch (e) {
      console.error(e);
      return false;
    }
  },

  async sendCommandSms(contract, command) {
    try {
      await api_auth.post(`${URLS.COMANDOS}/${contract}/sms/${encodeURIComponent(command)}`);
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  },

  async sendCommandSmsLote(contract, commands) {
    try {
      await api_auth.post(`${URLS.COMANDOS}/${contract}/lote/sms`, {
        json: commands,
      });
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  },

  async sendCommandSocket(contract, command) {
    try {
      await api_auth.post(`${URLS.COMANDOS}/${contract}/socket/${encodeURIComponent(command)}`);
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  },

  async sendCommandSocketLote(contract, commands) {
    try {
      await api_auth.post(`${URLS.COMANDOS}/${contract}/lote/socket`, {
        json: commands
      });
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  },

  async isTheft(contract) {
    try {
      return await api_auth.get(`${URLS.FURTO_ROUBO}/em-aberto/${contract}`).json();
    } catch (e) {
      console.error(e);
      return false;
    }
  },

  async enableTheft(contract) {
    try {
      const result = await api_auth.post(`${URLS.FURTO_ROUBO}/inicia-furto/${contract}`);
      console.log(result);
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  },

  async disableTheft(contract) {
    try {
      await api_auth.post(`${URLS.FURTO_ROUBO}/em-aberto/${contract}/termina`);
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  },

  async enableEmergencyMode(contract) {
    try {
      await api_auth.post(`${URLS.FURTO_ROUBO}/em-aberto/${contract}/modo-emergencia`);
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  },

  async sendTemporaryLink(contract, phoneNumber) {
    try {
      await api_auth.post(`${URLS.FURTO_ROUBO}/em-aberto/${contract}/envia-url/${phoneNumber}`);
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  }
}