<script>
    import { Link } from 'svero';
  </script>
  
  <main class="forgot-password-page">
    <div class="body">
      <img src="/img/logo-white.png" alt="Mercurio" class="logo">
      <h2>Recuperação de Senha</h2>
      <div class="form">
        <Link class="back-action" href="/login">
          <img src="/img/icon-arrow-back.svg" alt="">
          Voltar
        </Link>
        <p>Caso você tenha esquecido ou perdido sua senha, por favor, entre em contato com a equipe de RH da Loovi pessoalmente, ou pelo email abaixo:</p>
        <a href="mailto:grupoti@loovi.com.br">grupoti@loovi.com.br</a>
      </div>
      <div class="footer">
        <p>Produto de propriedade Loovi. Todos os direitos reservados.</p>
        <p>by <img src="/img/logo-loovi.svg" alt="Loovi"></p>
      </div>
    </div>
  </main>
  
  <style>
    .forgot-password-page {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    .forgot-password-page:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 50%;
      background: linear-gradient(90deg, var(--color-blue), var(--color-green));
      z-index: var(--z-1);
    }
  
    .body {
      position: relative;
      z-index: var(--z-2);
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    .body h2 {
      font-size: 2rem;
      font-weight: bold;
      color: #fff;
      margin: 25px 0;
    }
  
    .logo {
      width: 132px;
    }
  
    .form {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 340px;
      padding: 15px 18px;
      background: #fff;
      border-radius: 5px;
      box-shadow: 0 5px 20px rgba(0,0,0,0.1);
    }

    .form p {
      font-size: 1.4rem;
      line-height: 1.8rem;
      margin-bottom: 20px;
    }

    .form a {
      font-size: 1.4rem;
    }

    .body :global(.back-action) {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;
      color: var(--color-blue-dark);
    }

    .body :global(.back-action img) {
      margin-right: 5px;
    }
  
    .footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 400px;
      margin-top: 10px;
      color: var(--color-blue-dark);
    }
  
    .footer p {
      margin: 5px 0;
    }
  </style>