import ContractService from '../services/Contract';
import { contract } from '../stores/Contract';
import Popup from '../components/Popup.svelte';
import { setTemporaryIdToken } from '../stores/Auth';

export async function setContract(newContract, isTemporary = false, idToken = '') {
  // Caso o contrato seja o mesmo já na store, finaliza a função.
  if (newContract === contract.get().contractNumber) {
    return true;
  }
  let contractData;

  if (isTemporary && idToken) {
    setTemporaryIdToken(idToken);
    contractData = await ContractService.getContractTemporary(newContract, idToken);
  } else {
    contractData = await ContractService.getContract(newContract);
  }

  if (contractData) {
    let phoneData;
    
    if (!isTemporary) {
      phoneData = await ContractService.getPhoneData((contractData.Dispositivo || {}).Imei);
    }

    const data = {
      clientName: contractData.Nome || 'Usuário',
			contractNumber: newContract,
			imei: contractData.Dispositivo.Imei,
			plate: contractData.Veiculo.Placa,
			vehicle: contractData.Veiculo.Modelo,
			picturesStatus: contractData.StatusFotos,
      telefonia: phoneData || {},
      isTemporary: isTemporary,
      raw: contractData,
    };

    if (idToken) {
      data.idToken = idToken;
    }

    contract.set(data);

    return true;
  } else {
    return false;
  }
}
