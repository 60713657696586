<script>
	import { Link } from 'svero';
	import Menu from '../components/Menu.svelte';
	import Heading from '../components/Heading.svelte';
	import Footer from '../components/Footer.svelte';
	import Loader from '../components/Loader.svelte';
	import { contract } from '../stores/Contract';
	import ContractService from '../services/Contract';
	import { isLoggedAdmin } from '../stores/Auth.js';
	import Popup from '../components/Popup.svelte';
	
	let contractField = '';
	let isLoading = false;
	const contractOrImeiRegex = /[^0-9\-]/g;
	let isPopupOpen = false;
	
	let isAdmin = isLoggedAdmin();

	async function handleForm(){
		isLoading = true;
		isPopupOpen = false;
		
		const contractData = await ContractService.getContract(contractField);

		if (!contractData) {
			isLoading = false;
			isPopupOpen = true;
			return;
    	}
    
    	let phoneData = false;

		if ((contractData.Dispositivo || {}).Imei) {
		phoneData = await ContractService.getPhoneData((contractData.Dispositivo || {}).Imei);
		}

		$contract = {
			clientName: contractData.Nome || 'Usuário',
			contractNumber: contractData.Contrato,
			imei: contractData.Dispositivo.Imei,
			plate: contractData.Veiculo.Placa,
			vehicle: contractData.Veiculo.Modelo,
			picturesStatus: contractData.StatusFotos,
			telefonia: phoneData || {},
			raw: contractData,
		};

		isLoading = false;
	}

	function resetContract() {
		isLoading = true;

		setTimeout(() => {
			contractField = '';
			contract.reset();
			isLoading = false;
		}, 400);
  	}
  
	function handleKeypress(e) {
		if (contractOrImeiRegex.exec(e.key)) {
		e.preventDefault();
		return false;
		}
	}

	function handleInput(e) {
		contractField = e.target.value.replace(contractOrImeiRegex, '');
	}
</script>

<Menu showNav={!!$contract.contract} />

<Loader visible={isLoading} />

<Popup
    visible={isPopupOpen}
    title="Falha"
    message="Contrato ou IMEI não encontrado."
/>

<main>
	{#if $contract.contract}
		<div class="search-contract">
			<Heading main="Contrato/IMEI Selecionado." centered={true} />
			<p>
				Você está com o contrato
				<b>{$contract.contractNumber}</b>
				selecionado e agora pode navegar pelo sistema.
			</p>
			<button class="btnBuscarContrato" on:click={resetContract}>Buscar novo Contrato/IMEI</button>
		</div>
	{:else}
		<div class="search-contract">
			<Heading main="Buscar Contrato ou IMEI" centered={true} />
			<p>Para usar o sistema, você precisa primeiro selecionar um contrato/IMEI:</p>
			<form on:submit|preventDefault={handleForm}>
				<div class="field-wrapper">
					<input type="text" class="field" bind:value={contractField} on:keypress={handleKeypress} on:input={handleInput} />
					<button class="btn btn--icon">
						<img src="/img/icon-arrow-right.svg" alt="" />
					</button>
				</div>
			</form>
		</div>
	{/if}
	<div class="general-actions">
		<Link class="btn btn--outline" href="/buscar-furto-roubo">Veículos em Furto e Roubo</Link>
   		<Link class="btn btn--outline" href="/buscar-erros">Eventos de Veículos</Link>
    	<Link class="btn btn--outline" href="/eventos/nao-associados">Dispositivos não Associados</Link>
	</div>

	{#if isAdmin}
		<div class="general-actions2">
			<Link class="btn btn--outline" href="/sms">Enviar Sms</Link>
			<span>&nbsp;&nbsp;&nbsp;</span>
			<Link class="btn btn--outline" href="/upload-fotos">Enviar Fotos</Link>
			<span>&nbsp;&nbsp;&nbsp;</span>
			<Link class="btn btn--outline" href="/criar-email">Criar Gmail</Link>
			<span>&nbsp;&nbsp;&nbsp;</span>
			<Link class="btn btn--outline" href="/criar-usuario">Criar Usuário</Link>
		</div>
	{/if}
</main>

<Footer />

<style>
	main {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}

	.search-contract {
		text-align: center;
	}

	p {
		margin-top: 15px;
		font-size: 1.5rem;
		line-height: 1.8rem;
	}

	.field-wrapper {
		margin: 25px auto 0;
		max-width: 256px;
	}

	.general-actions {
		margin-top: 40px;
	}

	.general-actions :global(.btn) {
		min-width: 180px;
	}

	.general-actions :global(.btn + .btn) {
		margin-left: 5px;
		margin-top: 20px;
	}

	.general-actions2{
		display: flex;
		justify-content: space-between;
		margin-top: 15px;
	}

	.btnBuscarContrato{
		display: inline-flex;
		align-items: center;
		justify-content: center;
		text-decoration: none;
		border: none;
		border-radius: 5px;
		color: #fff;
		background: var(--color-blue-dark);
		min-width: 100px;
		height: 34px;
		font-family: inherit;
		font-size: 1.3rem;
		font-weight: bold;
		text-align: center;
		padding: 8px 20px;
		transition: background 0.2s linear;
		margin-top: 25px;
		cursor: pointer;
	}

	.btnBuscarContrato:hover{
		background-color: #8c9be0;
	}
</style>