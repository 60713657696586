<script>
    import Loader from './Loader.svelte';
    import Popup from './Popup.svelte';
    import Sms from '../services/Sms';
    import Heading from '../components/Heading.svelte';
  
    let isPopupOpen = false;
    let isLoading = false;
    let msg = "";
    let csvContent = "";
    let listaDeTelefones = "";
    let nomeArquivo = "";
  
    let msgAlerta = "";

    async function handleSubmit(e){
        e.preventDefault();

        if(msg.length > 0 && nomeArquivo.length > 0){
            isLoading = true;
    
            let bodyData = [];
    
            for(let i=0; i<listaDeTelefones.length; i++){
                bodyData.push({
                    "NumeroTelefone": listaDeTelefones[i],
                    "TextoMensagem": msg,
                    "Agendamento": 1595526192 
                })
            }
    
            const result = await Sms.postLoteEnvia(bodyData);
    
            if(result){
            limpaInputCsv();
            }
            if(!result.error){
                isLoading = false;
                isPopupOpen = true;
                limpaInputCsv();
            }
            if(result.error || !result){
                isLoading = false;
                return;
            }
        }else if(!nomeArquivo.length > 0){
            msgAlerta = "Nenhum arquivo selecionado";
        }else if(!msg.length > 0){
            msgAlerta = "Insira um texto"
        }
    }
  
    async function handleFileInput(event) {
        msgAlerta = "";
        isLoading = true;

        const file = event.target.files[0];
        nomeArquivo = file.name
        
        if (!file) {
            console.error("Nenhum arquivo selecionado.");
            isLoading = false;
            return;
        }

        const reader = new FileReader();

        reader.onload = async (e) => {
            isLoading = false;
            csvContent = e.target.result;
            listaDeTelefones = csvContent.replaceAll('\r', '').split('\n');
        };

        reader.onerror = (e) => {
            console.error("Erro ao ler o arquivo:", e.target.error);
        };

        reader.readAsText(file);
    }
  
    async function handleTextArea(event) {
        msg = event.target.value;
    }

    function limpaInputCsv(){
        nomeArquivo = "";
        csvContent = "";
        listaDeTelefones = "";
        msgAlerta = "";
        msg = "";
    }
</script>
  
<Loader visible={isLoading} />

<main>
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <div class="pai">
        <Heading main="Enviar SMS" />

        <div class="painel">
            <form on:submit={handleSubmit}>
                <label for="csv_file">
                    Selecione um arquivo CSV com os números de telefone (no formato '5531987642091' e separados por quebras de linha):<br>
                </label>

                {#if nomeArquivo.length > 0}
                    <label on:click={limpaInputCsv} class="preview" for="arquivo" aria-label="Clique para excluir" data-balloon-pos="down">{nomeArquivo}</label>
                {:else}
                    <input class="inputCsv" type="file" id="csv_file" on:change={handleFileInput} accept=".csv" placeholder="teste">
                    <label class="labelInputFile" for="csv_file">Selecionar arquivo</label>
                {/if}

                <label for="texto">Digite o texto a ser enviado:</label>
                <textarea id="texto" name="texto" rows="4" cols="50" maxlength="1280" on:input={handleTextArea} value={msg}></textarea>

                <div class="divSubmit">
                    <input class="btn" type="submit" value="Enviar">
                </div>

                {#if msgAlerta.length > 0}
                    <div class="msgAlerta">
                        <h1>{msgAlerta}</h1>
                    </div>
                {/if}
            </form>
        </div>
    </div>
</main>
  
<Popup
    visible={isPopupOpen}
    title="Executado com Sucesso!"
    message="SMS enviado com sucesso."
/>

<style>
    .labelInputFile{
        border-radius: 3px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        width: 178px;
        font-size: 10px;
        background: rgba(57, 198, 57, 0.3);
        color: black;
        text-transform: uppercase;
        display: block;
        margin-top: 10px;
        text-align: center;
        cursor: pointer;
        transition: .5s;
        border: 1px solid #333;
        font-weight: bold;
        margin-bottom: 35px;
    }

    .labelInputFile:hover{
        color: #333;
        background: rgba(57, 198, 57, 0.1);
    }

    .preview{
        border-radius: 3px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        width: 178px;
        font-size: 10px;
        background: rgba(57, 198, 57, 0.3);
        color: black;
        text-transform: uppercase;
        display: block;
        margin-top: 10px;
        text-align: center;
        cursor: pointer;
        transition: .5s;
        border: 1px solid #333;
        font-weight: bold;
        margin-bottom: 35px;
    }

    .preview:hover{
        background-color: #FD4545;
        color: white;
        border-radius: 3px;
    }

    .pai{
        max-width: 1004px;
        margin: 0 auto;
    }

    .painel{
        background: #fff;
        border-radius: 5px;
        border: 1px solid var(--color-black-20);
    }

    form{
        padding: 11px 13px;
    }

    form label{
        display: block;
        font-size: 15px;
        margin-bottom: px;
        margin-top: 10px;
        font-weight: 400;
    }

    .divSubmit{
        text-align: center;
        margin-top: 20px;
    }

    .btn{
        cursor: pointer;
    }

    .inputCsv{
        display: none;
    }

    .msgAlerta{
        background-color: red;
        color: white;
        font-weight: bold;
        text-align: center;
        padding: 10px;
        margin-top: 15px;
    }
</style>